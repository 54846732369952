import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import '../../styles/style.css';
import RelatedNews from './RelatedNews';
import { newsAPI } from '../utils/api';

const NewsDetail = React.memo(() => {
  const [news, setNews] = useState(null);
  const [error, setError] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [newsLanguage, setNewsLanguage] = useState('ru');
  const { slug } = useParams();
  const viewRegistered = useRef(false);
  const isComponentMounted = useRef(true);
  const abortController = useRef(null);
  const imageRef = useRef(null);
  const [newsId, setNewsId] = useState(null);

  const detectLanguage = (text) => {
    const polishChars = /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/;
    const romanianChars = /[ăâîșțĂÂÎȘȚ]/;
    
    if (polishChars.test(text)) {
      return 'pl';
    }
    if (romanianChars.test(text)) {
      return 'ro';
    }
    return 'ru';
  };

  const getButtonText = (lang) => {
    switch (lang) {
      case 'pl':
        return 'Czytaj więcej';
      case 'ro':
        return 'Citește mai mult';
      default:
        return 'Читать полностью';
    }
  };

  const registerView = async (newsId) => {
    if (!newsId || viewRegistered.current || !isComponentMounted.current) return;
    
    try {
      viewRegistered.current = true;
      await axios.post(`/api/public/news/view/${newsId}`);
    } catch (error) {
      viewRegistered.current = false;
    }
  };

  // Эффект для регистрации показа после загрузки изображения
  useEffect(() => {
    if (news && isImageLoaded && !viewRegistered.current) {
      newsAPI.registerView(news._id);
      viewRegistered.current = true;
    }
  }, [news, isImageLoaded]);

  const handleClick = React.useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (!news?._id || !slug) return;

    const controller = new AbortController();

    try {
        // Регистрируем клик
        await newsAPI.registerClick(news._id);

        // Получаем URL для редиректа
        const response = await newsAPI.getRedirectUrl(slug);
        
        if (response.data && response.data.redirectUrl) {
            const link = document.createElement('a');
            link.href = response.data.redirectUrl;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            controller.abort();
            return true;
        }
    } catch (error) {
        if (error.name === 'CanceledError') {
            return true;
        }
        
        // В случае других ошибок используем запасной вариант
        if (news?.sourceUrl) {
            let url = news.sourceUrl;
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'https://' + url;
            }
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return true;
        }
    }
    return false;
  }, [news, slug]);

  const fetchNewsCallback = React.useCallback(async () => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`/api/public/news/by-slug/${slug}`, {
          headers: {
            'Cache-Control': 'max-age=3600'
          }
        });
        
        if (!isComponentMounted.current) return;
        
        if (!response.data) {
          setError('Новость не найдена');
          return;
        }

        const newsData = {
          _id: response.data._id,
          title: response.data.title,
          description: response.data.description || response.data.title,
          image_url: response.data.imageUrl.startsWith('http')
            ? response.data.imageUrl
            : `${window.location.origin}${response.data.imageUrl}`,
          sourceUrl: response.data.sourceUrl,
          publishedAt: response.data.publishedAt,
          category: response.data.category
        };
        
        setNews(newsData);
        setNewsLanguage(detectLanguage(newsData.title));
      } catch (error) {
        if (isComponentMounted.current) {
          setError(error.response?.data?.message || 'Новость не найдена');
        }
      }
    };

    isComponentMounted.current = true;
    viewRegistered.current = false;
    setIsImageLoaded(false);
    fetchNews();

    return () => {
      isComponentMounted.current = false;
    };
  }, [slug]);

  useEffect(() => {
    fetchNewsCallback();
  }, [fetchNewsCallback]);

  const handleImageLoad = React.useCallback(() => {
    if (isComponentMounted.current) {
      setIsImageLoaded(true);
    }
  }, []);

  useEffect(() => {
    // Получаем данные новости
    const fetchNewsData = async () => {
        try {
            const response = await newsAPI.getBySlug(slug);
            setNewsId(response.data._id);
        } catch (error) {
            // Оставляем пустым для тихой обработки ошибок
        }
    };

    if (slug) {
        fetchNewsData();
    }
  }, [slug]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>Ошибка | Новость не найдена</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="container container-list w-100">
          <div className="error-container">
            <div className="error">
              <h2>Ошибка</h2>
              <p>{error}</p>
              <p>Возможно, новость была удалена или перемещена.</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!news) {
    return (
      <>
        <Helmet>
          <title>Loading...</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="container container-list w-100">
          <div className="loading">Loading...</div>
        </div>
      </>
    );
  }

  const redirectUrl = `/forward/news/${slug}`;
  const canonicalUrl = `${window.location.origin}/news/${slug}`;

  return (
    <>
      <Helmet>
        <title>{news.title}</title>
        <link rel="preload" href={news.image_url} as="image" />
        <meta name="description" content={news.title} />
        <meta property="og:title" content={news.title} />
        <meta property="og:description" content={news.title} />
        <meta property="og:image" content={news.image_url} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={news.title} />
        <meta name="twitter:description" content={news.title} />
        <meta name="twitter:image" content={news.image_url} />
        <link rel="canonical" href={canonicalUrl} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": news.title,
            "image": [news.image_url],
            "datePublished": news.publishedAt,
            "url": canonicalUrl
          })}
        </script>
      </Helmet>
      <div className="container container-list w-100">
        <div className="items-box" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="mobile-top-news">
            <RelatedNews 
              currentNewsId={newsId}
              currentSlug={slug}
              currentLanguage={newsLanguage}
              isMobileTop={true}
              itemsCount={1}
            />
          </div>
          
          <div className="item item-t item-p">
            <div className="article-preview">
              <a
                href={redirectUrl}
                onClick={handleClick}
                className="article-link"
                rel="noopener noreferrer"
                style={{ cursor: 'pointer' }}
              />
              <div className="item-overlay" />
              <div className="item-overlay-blur">
                <img 
                  src={news.image_url} 
                  alt={news.title}
                  onLoad={handleImageLoad}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/fallback-image.jpg';
                  }}
                  ref={imageRef}
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="item-image">
                <img 
                  src={news.image_url} 
                  alt={news.title}
                  onLoad={handleImageLoad}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/fallback-image.jpg';
                  }}
                  ref={imageRef}
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="item-title" data-slug={slug}>{news.title}</div>
              <div className="item-button">{getButtonText(newsLanguage)}</div>
            </div>
          </div>
          <div className="items-box" id="related-news" style={{ display: 'contents' }}>
            <RelatedNews 
              currentNewsId={newsId}
              currentSlug={slug}
              currentLanguage={newsLanguage}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default NewsDetail;