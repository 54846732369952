import React, { useCallback } from 'react';
import { tableStyles } from '../../pages/News/NewsList.styles';
import { api } from '../../utils/api';

const NewsTablePagination = ({
    news,
    setNews,
    currentPage,
    setCurrentPage,
    totalCount,
    setTotalCount,
    rowsPerPage,
    setRowsPerPage,
    searchQuery,
    statusFilter,
    languageFilter,
    setIsLoading,
    setError,
    navigate
}) => {
    const totalPages = Math.max(1, Math.ceil(totalCount / rowsPerPage));
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, totalCount);

    const fetchNewsData = useCallback(async (page, limit) => {
        setIsLoading(true);
        try {
            const params = {
                page,
                limit,
                ...(searchQuery && { search: searchQuery }),
                ...(statusFilter !== 'all' && { status: statusFilter }),
                ...(languageFilter !== 'all' && { language: languageFilter })
            };

            console.log('Fetching news with params:', params);
            const response = await api.get('/admin/news', { params });
            console.log('API Response:', response.data);

            if (response.data) {
                const newsData = response.data.news || [];
                console.log('Setting news data:', newsData);
                setNews(newsData);
                setTotalCount(response.data.totalNews || 0);
                setCurrentPage(page);
            }
        } catch (err) {
            console.error('Error in fetchNewsData:', err);
            if (err.response?.status === 401) {
                navigate('/login');
            } else {
                setError('Ошибка при загрузке новостей');
            }
        } finally {
            setIsLoading(false);
        }
    }, [searchQuery, statusFilter, languageFilter, setNews, setTotalCount, setCurrentPage, setIsLoading, setError, navigate]);

    const handlePageChange = async (page) => {
        console.log('Handling page change:', page);
        if (page >= 1 && page <= totalPages) {
            await fetchNewsData(page, rowsPerPage);
        }
    };

    const handleRowsPerPageChange = async (event) => {
        const newLimit = parseInt(event.target.value, 10);
        setRowsPerPage(newLimit);
        await fetchNewsData(1, newLimit);
    };

    const paginationButtonStyle = (isDisabled) => ({
        ...tableStyles.paginationButton,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        opacity: isDisabled ? 0.5 : 1,
        backgroundColor: isDisabled ? '#f8fafc' : 'white',
        pointerEvents: isDisabled ? 'none' : 'auto'
    });

    return (
        <div style={tableStyles.pagination}>
            <div style={tableStyles.rowsPerPage}>
                <span style={tableStyles.rowsPerPageLabel}>Строк на странице:</span>
                <select 
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    style={tableStyles.rowsPerPageSelect}
                >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
            <div style={tableStyles.paginationControls}>
                <button
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                    style={paginationButtonStyle(currentPage === 1)}
                >
                    ««
                </button>
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={paginationButtonStyle(currentPage === 1)}
                >
                    «
                </button>
                <span style={tableStyles.paginationInfo}>
                    {startIndex + 1}-{endIndex} из {totalCount}
                </span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={paginationButtonStyle(currentPage === totalPages)}
                >
                    »
                </button>
                <button
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    style={paginationButtonStyle(currentPage === totalPages)}
                >
                    »»
                </button>
            </div>
        </div>
    );
};

export default NewsTablePagination; 