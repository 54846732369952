import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import BarChartIcon from '@mui/icons-material/BarChart';
import StatsOverview from '../../components/Stats/StatsOverview';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { api, BASE_DOMAIN } from '../../utils/api';
import { format, subDays, startOfDay } from 'date-fns';
import {
    tableStyles,
    imageStyles,
    buttonStyles,
    layoutStyles,
    searchStyles,
    newsTitleStyles
} from './NewsList.styles';
import '../../components/Toggle/Toggle.css';
import { debounce } from 'lodash';
import NewsTableRow, { NewsTableHeaderColumns } from '../../components/News/NewsTableRow';
import NewsTableFilters from '../../components/News/NewsTableFilters';
import NewsTablePagination from '../../components/News/NewsTablePagination';
import axios from 'axios';

const getLanguageName = (code) => {
    const languages = {
        'ru': 'Русский',
        'pl': 'Польский',
        'ro': 'Румынский'
    };
    return languages[code] || code;
};

const getStatsByLanguage = (allNews) => {
    const stats = {
        ru: { active: 0, inactive: 0 },
        pl: { active: 0, inactive: 0 },
        ro: { active: 0, inactive: 0 }
    };

    allNews.forEach(item => {
        if (stats[item.language]) {
            if (item.isActive) {
                stats[item.language].active++;
            } else {
                stats[item.language].inactive++;
            }
        }
    });

    return stats;
};

const NewsList = () => {
    const navigate = useNavigate();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState({
        startDate: startOfDay(subDays(new Date(), 6)),
        endDate: startOfDay(new Date())
    });
    const [statusFilter, setStatusFilter] = useState('all');
    const [showStatusMenu, setShowStatusMenu] = useState(false);
    const [languageFilter, setLanguageFilter] = useState('all');
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    const [isUpdatingStats, setIsUpdatingStats] = useState(false);
    const [showMenu, setShowMenu] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [allNews, setAllNews] = useState([]);
    const [newsStats, setNewsStats] = useState({
        ru: { active: 0, inactive: 0 },
        pl: { active: 0, inactive: 0 },
        ro: { active: 0, inactive: 0 }
    });
    const [selectedDate, setSelectedDate] = useState(null);
    const [historicalStats, setHistoricalStats] = useState([]);
    const [statsUpdateCounter, setStatsUpdateCounter] = useState(0);
    const [tableTotals, setTableTotals] = useState({
        views: 0,
        clicks: 0,
        adsViews: 0,
        adsClicks: 0
    });
    const [forceUpdate, setForceUpdate] = useState(false);

    const statusMenuRef = useRef(null);
    const languageMenuRef = useRef(null);

    const getFilteredNews = useCallback(() => {
        return news.filter(item => {
            const matchesSearch = !searchQuery ? true : (
                item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.sourceUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item._id.toString().includes(searchQuery) ||
                item.orderNumber?.toString().includes(searchQuery)
            );

            const matchesStatus = statusFilter === 'all' ? true : 
                (statusFilter === 'active' ? item.isActive : !item.isActive);

            const matchesLanguage = languageFilter === 'all' ? true : 
                item.language === languageFilter;

            return matchesSearch && matchesStatus && matchesLanguage;
        });
    }, [news, searchQuery, statusFilter, languageFilter]);

    const getSortedAndPaginatedNews = useCallback(() => {
        const filtered = getFilteredNews();
        return filtered;
    }, [getFilteredNews]);

    const fetchNewsData = useCallback(async (page, limit) => {
        if (isLoading) return;
        
        setIsLoading(true);
        try {
            const params = {
                page,
                limit,
                ...(searchQuery && { search: searchQuery }),
                ...(statusFilter !== 'all' && { isActive: statusFilter === 'active' }),
                ...(languageFilter !== 'all' && { language: languageFilter })
            };

            console.log('Fetching news with params:', params);
            console.log('Current filters - Search:', searchQuery, 'Status:', statusFilter, 'Language:', languageFilter);

            const response = await api.get('/admin/news', { params });
            
            if (response.data) {
                console.log('Received news:', response.data.news.length, 'items');
                setNews(response.data.news || []);
                setTotalCount(response.data.totalNews || 0);
                setCurrentPage(response.data.currentPage || 1);
            }
        } catch (err) {
            console.error('Error fetching news:', err);
            setError('Ошибка при загрузке новостей');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    }, [isLoading, searchQuery, statusFilter, languageFilter]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        // Загружаем отфильтрованные новости
        fetchNewsData(1, rowsPerPage);

        // Загружаем все новости для статистики
        api.get('/admin/news', { params: { page: 1, limit: 1000 } })
            .then(response => {
                if (response.data) {
                    const allNewsData = response.data.news || [];
                    setAllNews(allNewsData);
                    // Инициализируем статистику
                    const initialStats = getStatsByLanguage(allNewsData);
                    setNewsStats(initialStats);
                }
            })
            .catch(err => {
                console.error('Error fetching all news:', err);
            });
    }, []); // Выполняется только при монтировании компонента

    useEffect(() => {
        setShowBulkActions(selectedItems.size > 0);
    }, [selectedItems]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
                setShowStatusMenu(false);
            }
            if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
                setShowLanguageMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelectItem = (id) => {
        const newSelected = new Set(selectedItems);
        if (newSelected.has(id)) {
            newSelected.delete(id);
        } else {
            newSelected.add(id);
        }
        setSelectedItems(newSelected);
    };

    const handleSelectAll = (event) => {
        setSelectedItems(event.target.checked ? new Set(news.map(item => item._id)) : new Set());
    };

    const handleToggleActive = async (id, currentStatus) => {
        try {
            await api.put(`/admin/news/${id}/status`, { isActive: !currentStatus });
            
            // Обновляем локальное состояние новостей
            setNews(prevNews => prevNews.map(item => {
                if (item._id === id) {
                    return { ...item, isActive: !currentStatus };
                }
                return item;
            }));

            // Обновляем общую статистику
            setAllNews(prevAllNews => {
                const updatedNews = prevAllNews.map(item => {
                    if (item._id === id) {
                        return { ...item, isActive: !currentStatus };
                    }
                    return item;
                });
                
                // Пересчитываем статистику
                const newStats = getStatsByLanguage(updatedNews);
                setNewsStats(newStats); // Обновляем состояние статистики
                
                return updatedNews;
            });
            
            // Если нужно обновить другие данные с сервера
            fetchNewsData(currentPage, rowsPerPage);
            
        } catch (err) {
            console.error('Error toggling news status:', err);
            setError('Ошибка при изменении статуса новости');
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Вы уверены, что хотите удалить эту новость?')) {
            try {
                await api.delete(`/admin/news/${id}`);
                fetchNewsData();
            } catch (err) {
                console.error('Error deleting news:', err);
            }
        }
    };

    const handleBulkAction = async (action) => {
        const selectedIds = Array.from(selectedItems);

        try {
            switch (action) {
                case 'activate':
                    await Promise.all(selectedIds.map(id => 
                        api.put(`/admin/news/${id}/status`, { isActive: true })
                    ));
                    // Обновляем локальное состояние
                    setAllNews(prevAllNews => {
                        const updatedNews = prevAllNews.map(item => {
                            if (selectedIds.includes(item._id)) {
                                return { ...item, isActive: true };
                            }
                            return item;
                        });
                        const newStats = getStatsByLanguage(updatedNews);
                        setNewsStats(newStats);
                        return updatedNews;
                    });
                    break;
                case 'deactivate':
                    await Promise.all(selectedIds.map(id => 
                        api.put(`/admin/news/${id}/status`, { isActive: false })
                    ));
                    // Обновляем локальное состояние
                    setAllNews(prevAllNews => {
                        const updatedNews = prevAllNews.map(item => {
                            if (selectedIds.includes(item._id)) {
                                return { ...item, isActive: false };
                            }
                            return item;
                        });
                        const newStats = getStatsByLanguage(updatedNews);
                        setNewsStats(newStats);
                        return updatedNews;
                    });
                    break;
                case 'delete':
                    if (window.confirm('Вы уверены, что хотите удалить выбранные новости?')) {
                        await Promise.all(selectedIds.map(id => api.delete(`/admin/news/${id}`)));
                        // Обновляем локальное состояние
                        setAllNews(prevAllNews => {
                            const updatedNews = prevAllNews.filter(item => !selectedIds.includes(item._id));
                            const newStats = getStatsByLanguage(updatedNews);
                            setNewsStats(newStats);
                            return updatedNews;
                        });
                    }
                    break;
                case 'changeUrl':
                    const newUrl = prompt('Введите новый URL:');
                    if (newUrl) {
                        await Promise.all(selectedIds.map(id => 
                            api.put(`/admin/news/${id}`, { sourceUrl: newUrl })
                        ));
                    }
                    break;
            }

            // Обновляем отображаемые новости
            fetchNewsData(currentPage, rowsPerPage);
            setSelectedItems(new Set());
            setShowBulkActionsMenu(false);
        } catch (err) {
            console.error('Error performing bulk action:', err);
            setError('Ошибка при выполнении массового действия');
        }
    };

    const handleDateRangeChange = (newRange) => {
        if (newRange.startDate && newRange.endDate) {
            setDateRange({
                startDate: startOfDay(new Date(newRange.startDate)),
                endDate: startOfDay(new Date(newRange.endDate))
            });
        }
    };

    const handleSort = (field) => {
        setSortField(field);
        setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
        
        const sorted = [...news].sort((a, b) => {
            let aValue, bValue;
            
            switch(field) {
                // Поля трафика
                case 'views':
                    aValue = a.stats?.views || 0;
                    bValue = b.stats?.views || 0;
                    break;
                case 'clicks':
                    aValue = a.stats?.clicks || 0;
                    bValue = b.stats?.clicks || 0;
                    break;
                case 'ctr':
                    aValue = a.stats?.views ? (a.stats.clicks / a.stats.views) * 100 : 0;
                    bValue = b.stats?.views ? (b.stats.clicks / b.stats.views) * 100 : 0;
                    break;
                // Поля рекламы
                case 'adsViews':
                    aValue = a.adsStats?.views || 0;
                    bValue = b.adsStats?.views || 0;
                    break;
                case 'adsClicks':
                    aValue = a.adsStats?.clicks || 0;
                    bValue = b.adsStats?.clicks || 0;
                    break;
                case 'adsCtr':
                    aValue = a.adsStats?.views ? (a.adsStats.clicks / a.adsStats.views) * 100 : 0;
                    bValue = b.adsStats?.views ? (b.adsStats.clicks / b.adsStats.views) * 100 : 0;
                    break;
                default:
                    return 0;
            }
            
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        });
        
        setNews(sorted);
    };

    const handleResetFilters = useCallback(() => {
        setSearchQuery('');
        setStatusFilter('all');
        setLanguageFilter('all');
        setCurrentPage(1);
        
        const params = {
            page: 1,
            limit: rowsPerPage
        };
        
        setIsLoading(true);
        api.get('/admin/news', { params })
            .then(response => {
                if (response.data) {
                    setNews(response.data.news || []);
                    setTotalCount(response.data.totalNews || 0);
                    setCurrentPage(response.data.currentPage || 1);
                }
            })
            .catch(err => {
                console.error('Error resetting filters:', err);
                setError('Ошибка при сбросе фильтров');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [rowsPerPage]);

    const updateStats = async () => {
        try {
            setIsUpdatingStats(true);
            // Получаем все ID новостей из allNews, а не только из отфильтрованных news
            const newsIds = allNews.map(item => item._id);
            const response = await api.get('/admin/news/stats', {
                params: { ids: newsIds.join(',') }
            });
            
            if (response.data && response.data.news) {
                // Обновляем и news, и allNews
                const updatedStats = response.data.news;
                
                setNews(prevNews => prevNews.map(item => {
                    const updatedItem = updatedStats.find(n => n._id === item._id);
                    return updatedItem ? {
                        ...item,
                        stats: updatedItem.stats || item.stats,
                        mainStats: updatedItem.mainStats || item.mainStats
                    } : item;
                }));

                // Обновляем также allNews
                setAllNews(prevAllNews => prevAllNews.map(item => {
                    const updatedItem = updatedStats.find(n => n._id === item._id);
                    return updatedItem ? {
                        ...item,
                        stats: updatedItem.stats || item.stats,
                        mainStats: updatedItem.mainStats || item.mainStats
                    } : item;
                }));
            }
        } catch (err) {
            console.error('Ошибка при обновлении статистики:', err);
        } finally {
            setIsUpdatingStats(false);
        }
    };

    const stats = getStatsByLanguage(allNews);

    const avgCtr = tableTotals.views ? ((tableTotals.clicks / tableTotals.views) * 100).toFixed(2) : '0.00';
    const avgAdsCtr = tableTotals.adsViews ? ((tableTotals.adsClicks / tableTotals.adsViews) * 100).toFixed(2) : '0.00';

    const handleFilterChange = useCallback((newFilters = {}) => {
        setCurrentPage(1);

        const params = {
            page: 1,
            limit: rowsPerPage,
            ...(newFilters.search !== undefined && { search: newFilters.search.trim() }),
            ...(newFilters.status !== undefined 
                ? { status: newFilters.status === 'active' ? 1 : 0 }
                : statusFilter !== 'all' && { status: statusFilter === 'active' ? 1 : 0 }
            ),
            ...(newFilters.language !== undefined
                ? { language: newFilters.language }
                : languageFilter !== 'all' && { language: languageFilter }
            )
        };

        setIsLoading(true);

        // Получаем только отфильтрованные новости
        api.get('/admin/news', { params })
            .then(response => {
                if (response.data) {
                    setNews(response.data.news || []);
                    setTotalCount(response.data.totalNews || 0);
                    setCurrentPage(response.data.currentPage || 1);
                }
            })
            .catch(err => {
                setError('Ошибка при применении фильтров');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [searchQuery, statusFilter, languageFilter, rowsPerPage]);

    const handleStatsChange = (stats, date) => {
        console.log('Updating stats with date:', date);
        setHistoricalStats(stats);
        setSelectedDate(date);
    };

    useEffect(() => {
        console.log('historicalStats updated:', historicalStats);
    }, [historicalStats]);

    const handleStatsUpdated = (updatedNews) => {
        setNews(updatedNews); // Обновляем список новостей с новой статистикой
        setForceUpdate(prev => !prev); // Теперь у нас есть setForceUpdate
    };

    if (loading) {
        return <div style={{ textAlign: 'center', padding: '20px' }}>Загрузка...</div>;
    }

    if (error) {
        return <div style={{ color: 'red', textAlign: 'center', padding: '20px' }}>{error}</div>;
    }

    return (
        <div style={layoutStyles.container}>
            <div style={layoutStyles.content}>
                <div style={layoutStyles.header}>
                    <div style={layoutStyles.headerContent}>
                        <div style={layoutStyles.headerSectionLeft}>
                            <h1 style={layoutStyles.title}>Объявления</h1>
                        </div>
                        
                        <div style={layoutStyles.headerSectionCenter}>
                            <div style={layoutStyles.statsContainer}>
                                <span style={layoutStyles.statsTitle}>
                                    Количество новостей (Язык: активно / неактивно) -
                                </span>
                                <div style={layoutStyles.statsContent}>
                                    {Object.entries(newsStats).map(([lang, stat], index) => (
                                        <React.Fragment key={lang}>
                                            <span style={layoutStyles.statsItem}>
                                                {getLanguageName(lang)}: {' '}
                                                <span style={layoutStyles.statsActive}>{stat.active}</span>
                                                {' / '}
                                                <span style={layoutStyles.statsInactive}>{stat.inactive}</span>
                                            </span>
                                            {index < Object.entries(newsStats).length - 1 && (
                                                <span style={layoutStyles.statsDivider}>|</span>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div style={layoutStyles.headerSectionRight}>
                            <div style={layoutStyles.headerControls}>
                    <button
                        onClick={() => navigate('/admin/news/create')}
                        style={buttonStyles.primary}
                        onMouseOver={e => e.target.style.backgroundColor = '#1e3c72'}
                        onMouseOut={e => e.target.style.backgroundColor = '#2a5298'}
                    >
                        + Новое объявление
                    </button>
                                <DateRangePicker onStatsChange={handleStatsChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={layoutStyles.mainSection}>
                    <NewsTableFilters
                        selectedItems={selectedItems}
                        showBulkActionsMenu={showBulkActionsMenu}
                        setShowBulkActionsMenu={setShowBulkActionsMenu}
                        onBulkAction={handleBulkAction}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        showStatusMenu={showStatusMenu}
                        setShowStatusMenu={setShowStatusMenu}
                        languageFilter={languageFilter}
                        setLanguageFilter={setLanguageFilter}
                        showLanguageMenu={showLanguageMenu}
                        setShowLanguageMenu={setShowLanguageMenu}
                        handleResetFilters={handleResetFilters}
                        updateStats={updateStats}
                        isUpdatingStats={isUpdatingStats}
                        setIsUpdatingStats={setIsUpdatingStats}
                        onFilterChange={handleFilterChange}
                        onStatsUpdated={handleStatsUpdated}
                        newsStats={newsStats}
                    />

                    {news.length === 0 ? (
                        <div style={{ textAlign: 'center', padding: '40px', color: '#6c757d' }}>
                            {searchQuery ? 'Ничего не найдено' : 'Новости пока отсутствуют'}
                    </div>
                ) : (
                        <div style={tableStyles.container}>
                        <table style={{ 
                            width: '100%', 
                            borderCollapse: 'collapse',
                            tableLayout: 'fixed'
                        }}>
                            <NewsTableHeaderColumns 
                                handleSelectAll={handleSelectAll}
                                filteredAndSortedNews={news}
                                selectedItems={selectedItems}
                                tableStyles={tableStyles}
                                handleSort={handleSort}
                                sortField={sortField}
                                sortDirection={sortDirection}
                                allNews={allNews}
                                historicalStats={historicalStats}
                                selectedDate={selectedDate}
                                forceUpdate={statsUpdateCounter}
                                onTotalsCalculated={setTableTotals}
                            />
                            <tbody>
                                    {news.map((item) => (
                                        <NewsTableRow
                                            key={item._id}
                                            item={item}
                                            selectedItems={selectedItems}
                                            handleSelectItem={handleSelectItem}
                                            handleToggleActive={handleToggleActive}
                                            handleDelete={handleDelete}
                                            showMenu={showMenu}
                                            setShowMenu={setShowMenu}
                                            getLanguageName={getLanguageName}
                                            selectedDate={selectedDate}
                                            historicalStats={historicalStats}
                                        />
                                ))}
                            </tbody>
                        </table>
                        <NewsTablePagination 
                            news={news}
                            setNews={setNews}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalCount={totalCount}
                            setTotalCount={setTotalCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            searchQuery={searchQuery}
                            statusFilter={statusFilter}
                            languageFilter={languageFilter}
                            setIsLoading={setIsLoading}
                            setError={setError}
                            navigate={navigate}
                        />
                    </div>
                )}
            </div>
            </div>
        </div>
    );
};

export default NewsList;