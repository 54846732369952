import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { 
    buttonStyles, 
    searchStyles, 
    layoutStyles,
    bulkActionsStyles
} from '../../pages/News/NewsList.styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { debounce } from 'lodash';
import { newsAPI } from '../../../public/utils/api';

const getLanguageName = (code) => {
    const languages = {
        'ru': 'Русский',
        'pl': 'Польский',
        'ro': 'Румынский'
    };
    return languages[code] || code;
};

const ActionButton = ({ icon: Icon, onClick, color, hoverColor, title }) => (
    <button
        onClick={onClick}
        title={title}
        style={{
            ...buttonStyles.action,
            color
        }}
        onMouseOver={e => {
            e.target.style.backgroundColor = '#f3f4f6';
            e.target.style.color = hoverColor;
        }}
        onMouseOut={e => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = color;
        }}
    >
        <Icon style={{ fontSize: 20 }} />
    </button>
);

const BulkActionsButton = ({ 
    selectedItems, 
    showBulkActionsMenu, 
    setShowBulkActionsMenu, 
    onBulkAction 
}) => (
    <div style={bulkActionsStyles.container}>
        <div style={{ position: 'relative' }}>
            <button
                onClick={() => selectedItems.size > 0 && setShowBulkActionsMenu(!showBulkActionsMenu)}
                style={bulkActionsStyles.button(selectedItems.size > 0)}
            >
                <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    {selectedItems.size > 0 ? (
                        <>
                            Выбрано объявлений:
                            <span style={bulkActionsStyles.counter}>
                                {selectedItems.size}
                            </span>
                        </>
                    ) : (
                        'Выберите объявления'
                    )}
                </span>
            </button>
            {showBulkActionsMenu && selectedItems.size > 0 && (
                <div style={bulkActionsStyles.menu}>
                    <button 
                        onClick={() => onBulkAction('activate')}
                        style={buttonStyles.bulkAction}
                    >
                        <PlayArrowIcon style={{ fontSize: 18 }} />
                        Включить
                    </button>
                    <button 
                        onClick={() => onBulkAction('deactivate')}
                        style={buttonStyles.bulkAction}
                    >
                        <PauseIcon style={{ fontSize: 18 }} />
                        Выключить
                    </button>
                    <button 
                        onClick={() => onBulkAction('changeUrl')}
                        style={buttonStyles.bulkAction}
                    >
                        <LinkIcon style={{ fontSize: 18 }} />
                        Изменить URL
                    </button>
                    <button 
                        onClick={() => onBulkAction('delete')}
                        style={{ ...buttonStyles.bulkAction, color: '#dc2626' }}
                    >
                        <DeleteIcon style={{ fontSize: 18 }} />
                        Удалить
                    </button>
                </div>
            )}
        </div>
    </div>
);

const NewsTableFilters = ({
    selectedItems,
    showBulkActionsMenu,
    setShowBulkActionsMenu,
    onBulkAction,
    searchQuery,
    setSearchQuery,
    statusFilter,
    setStatusFilter,
    showStatusMenu,
    setShowStatusMenu,
    languageFilter,
    setLanguageFilter,
    showLanguageMenu,
    setShowLanguageMenu,
    handleResetFilters,
    updateStats,
    isUpdatingStats,
    setIsUpdatingStats,
    onFilterChange,
    onStatsUpdated,
    newsStats
}) => {
    const statusMenuRef = useRef(null);
    const languageMenuRef = useRef(null);

    const handleSearch = useCallback((value) => {
        setSearchQuery(value);
        onFilterChange({ search: value });
    }, [setSearchQuery, onFilterChange]);

    const debouncedSearch = useRef(
        debounce((value) => {
            handleSearch(value);
        }, 300)
    ).current;

    const handleSearchInputChange = useCallback((e) => {
        const value = e.target.value;
        setSearchQuery(value);
        debouncedSearch(value);
    }, [setSearchQuery]);

    const handleStatusChange = useCallback((newStatus) => {
        setStatusFilter(newStatus);
        setShowStatusMenu(false);
        onFilterChange({ status: newStatus });
    }, [setStatusFilter, setShowStatusMenu, onFilterChange]);

    const handleLanguageChange = useCallback((newLanguage) => {
        setLanguageFilter(newLanguage);
        setShowLanguageMenu(false);
        onFilterChange({ language: newLanguage });
    }, [setLanguageFilter, setShowLanguageMenu, onFilterChange]);

    const handleReset = useCallback(() => {
        setSearchQuery('');
        setStatusFilter('all');
        setLanguageFilter('all');
        handleResetFilters();
    }, [setSearchQuery, setStatusFilter, setLanguageFilter, handleResetFilters]);

    const handleUpdateStats = async () => {
        try {
            setIsUpdatingStats(true);
            
            // Сначала обновляем текущую статистику
            await updateStats();
            
            // Получаем текущие фильтры
            const currentFilters = {
                page: 1,
                limit: 1000,
                search: searchQuery || undefined,
                status: statusFilter !== 'all' ? 
                    (statusFilter === 'active' ? 'active' : 'inactive') : undefined,
                language: languageFilter !== 'all' ? languageFilter : undefined
            };

            // Получаем отфильтрованные новости с текущими фильтрами
            const response = await newsAPI.getFilteredNews(currentFilters);

            if (response && response.news) {
                // Обновляем данные в родительском компоненте
                if (typeof onStatsUpdated === 'function') {
                    onStatsUpdated(response.news);
                }
            }
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = '/admin';
                return;
            }
            console.error('Ошибка при обновлении статистики:', error);
        } finally {
            setIsUpdatingStats(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
                setShowStatusMenu(false);
            }
            if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
                setShowLanguageMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowStatusMenu, setShowLanguageMenu]);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={layoutStyles.toolbarLeft}>
                <BulkActionsButton
                    selectedItems={selectedItems}
                    showBulkActionsMenu={showBulkActionsMenu}
                    setShowBulkActionsMenu={setShowBulkActionsMenu}
                    onBulkAction={onBulkAction}
                />
                <div style={searchStyles.container}>
                    <SearchIcon style={searchStyles.icon} />
                    <input
                        type="text"
                        placeholder="Введите URL, ID или заголовок объявления"
                        value={searchQuery || ''}
                        onChange={handleSearchInputChange}
                        style={searchStyles.input}
                    />
                </div>
                <div style={layoutStyles.statusFilter}>
                    <div ref={statusMenuRef}>
                        <button
                            onClick={() => setShowStatusMenu(!showStatusMenu)}
                            style={{
                                ...buttonStyles.filter,
                                backgroundColor: statusFilter !== 'all' ? '#e2e8f0' : 'white'
                            }}
                        >
                            Статус: {statusFilter === 'all' ? 'Все' : 
                                    statusFilter === 'active' ? 'Запущен' : 'Остановлен'}
                            <span style={{ marginLeft: '4px' }}>▼</span>
                        </button>
                        {showStatusMenu && (
                            <div style={layoutStyles.statusMenu}>
                                <button
                                    style={{
                                        ...buttonStyles.filterOption,
                                        backgroundColor: statusFilter === 'all' ? '#f1f5f9' : 'white'
                                    }}
                                    onClick={() => handleStatusChange('all')}
                                >
                                    Все
                                </button>
                                <button
                                    style={{
                                        ...buttonStyles.filterOption,
                                        backgroundColor: statusFilter === 'active' ? '#f1f5f9' : 'white'
                                    }}
                                    onClick={() => handleStatusChange('active')}
                                >
                                    Запущен
                                </button>
                                <button
                                    style={{
                                        ...buttonStyles.filterOption,
                                        backgroundColor: statusFilter === 'inactive' ? '#f1f5f9' : 'white'
                                    }}
                                    onClick={() => handleStatusChange('inactive')}
                                >
                                    Остановлен
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div style={layoutStyles.statusFilter}>
                    <div ref={languageMenuRef}>
                        <button
                            onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                            style={{
                                ...buttonStyles.filter,
                                backgroundColor: languageFilter !== 'all' ? '#e2e8f0' : 'white'
                            }}
                        >
                            Язык: {languageFilter === 'all' ? 'Все' : getLanguageName(languageFilter)}
                            <span style={{ marginLeft: '4px' }}>▼</span>
                        </button>
                        {showLanguageMenu && (
                            <div style={layoutStyles.statusMenu}>
                                <button
                                    style={{
                                        ...buttonStyles.filterOption,
                                        backgroundColor: languageFilter === 'all' ? '#f1f5f9' : 'white'
                                    }}
                                    onClick={() => handleLanguageChange('all')}
                                >
                                    Все
                                </button>
                                <button
                                    style={{
                                        ...buttonStyles.filterOption,
                                        backgroundColor: languageFilter === 'ru' ? '#f1f5f9' : 'white'
                                    }}
                                    onClick={() => handleLanguageChange('ru')}
                                >
                                    Русский
                                </button>
                                <button
                                    style={{
                                        ...buttonStyles.filterOption,
                                        backgroundColor: languageFilter === 'pl' ? '#f1f5f9' : 'white'
                                    }}
                                    onClick={() => handleLanguageChange('pl')}
                                >
                                    Польский
                                </button>
                                <button
                                    style={{
                                        ...buttonStyles.filterOption,
                                        backgroundColor: languageFilter === 'ro' ? '#f1f5f9' : 'white'
                                    }}
                                    onClick={() => handleLanguageChange('ro')}
                                >
                                    Румынский
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {(searchQuery || statusFilter !== 'all' || languageFilter !== 'all') && (
                    <button
                        onClick={handleReset}
                        style={{
                            ...buttonStyles.filter,
                            border: '1px solid #fee2e2',
                            color: '#dc2626',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            backgroundColor: 'white'
                        }}
                    >
                        Сбросить фильтры
                        <span style={{ 
                            fontSize: '16px',
                            fontWeight: 'bold',
                            marginLeft: '4px'
                        }}>×</span>
                    </button>
                )}
            </div>
            <button
                onClick={handleUpdateStats}
                disabled={isUpdatingStats}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    padding: '6px 12px',
                    backgroundColor: '#f8fafc',
                    color: '#ef4444',
                    border: '1px solid #e2e8f0',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '13px',
                    fontWeight: '500',
                    transition: 'all 0.2s ease',
                    height: '34px',
                    marginLeft: '8px',
                    ':hover': {
                        backgroundColor: '#f1f5f9',
                        borderColor: '#cbd5e1'
                    }
                }}
                title="Обновить статистику"
                className="refresh-button"
            >
                <RefreshIcon style={{ 
                    fontSize: 18,
                    animation: isUpdatingStats ? 'spin 1s linear infinite' : 'none',
                    color: '#ef4444'
                }} />
                Обновить статистику
            </button>
        </div>
    );
};

export default NewsTableFilters;
