import React from 'react';
import { labelStyles, formGroupStyles, errorMessageStyles } from './InputWrapper.styles';

const InputWrapper = ({ label, error, children }) => (
    <div style={formGroupStyles}>
        <label style={{ 
            ...labelStyles.base, 
            ...(error ? labelStyles.error : {})
        }}>
            {label}
        </label>
        {children}
        {error && (
            <div style={errorMessageStyles}>
                {error}
            </div>
        )}
    </div>
);

export default InputWrapper;