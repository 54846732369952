export const buttonStyles = {
    uploadButton: {
        display: 'inline-block',
        padding: '8px 16px',
        backgroundColor: '#2a5298',
        color: 'white',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        fontSize: '14px',
        border: 'none',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        fontWeight: '500',
        minWidth: '200px',
        textAlign: 'center',
        whiteSpace: 'nowrap'
    },
    removeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'rgba(255,255,255,0.9)',
        border: 'none',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        fontSize: '12px',
        transition: 'all 0.2s ease'
    }
};

export const imageStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    preview: {
        position: 'relative',
        width: 'fit-content'
    },
    previewImage: {
        width: '300px',
        height: '180px',
        objectFit: 'cover',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    helpText: {
        fontSize: '12px',
        color: '#666'
    }
}; 