import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format, subDays, eachDayOfInterval, startOfDay } from 'date-fns';
import AdminLayout from '../components/Layout';
import StatsCard from '../components/Dashboard/StatsCard';
import DateRangePicker from '../components/DateRangePicker/DateRangePicker';

const Dashboard = () => {
    const [chartType, setChartType] = useState('shows');
    const [dateRange, setDateRange] = useState({
        startDate: startOfDay(subDays(new Date(), 6)),
        endDate: startOfDay(new Date())
    });

    // Генерация стабильных случайных данных на основе даты
    const generateMockData = (date) => {
        const timestamp = date instanceof Date ? date.getTime() : new Date(date).getTime();
        const seededRandom = () => {
            const x = Math.sin(timestamp / 10000) * 10000;
            return Math.abs(x - Math.floor(x));
        };

        const shows = Math.floor(seededRandom() * 1000) + 800;
        const clicks = Math.floor(seededRandom() * 100) + 50;
        const ctr = (clicks / shows) * 100;
        
        return {
            shows,
            clicks,
            ctr
        };
    };

    // Состояние для хранения статистики
    const [stats, setStats] = useState({
        period: {
            shows: 0,
            clicks: 0,
            ctr: 0
        },
        chartData: []
    });

    // Обновление данных при изменении периода
    useEffect(() => {
        if (!dateRange.startDate || !dateRange.endDate) return;

        try {
            // Получаем все даты в выбранном периоде
            const dates = eachDayOfInterval({
                start: startOfDay(new Date(dateRange.startDate)),
                end: startOfDay(new Date(dateRange.endDate))
            });

            // Генерируем данные для каждого дня
            const newChartData = dates.map(date => ({
                date: format(date, 'dd.MM'),
                ...generateMockData(date)
            }));

            // Считаем общую статистику за период
            const periodStats = newChartData.reduce((acc, day) => ({
                shows: acc.shows + day.shows,
                clicks: acc.clicks + day.clicks,
                ctr: 0 // Пересчитаем после
            }), { shows: 0, clicks: 0, ctr: 0 });

            // Считаем средний CTR за период
            periodStats.ctr = (periodStats.clicks / periodStats.shows) * 100;

            setStats({
                period: periodStats,
                chartData: newChartData
            });
        } catch (error) {
            console.error('Error generating stats:', error);
        }
    }, [dateRange]);

    // Конфигурация типов графиков
    const chartTypes = {
        shows: {
            label: 'Показы',
            color: '#4F46E5',
            dataKey: 'shows',
            formatter: (value) => value.toLocaleString()
        },
        clicks: {
            label: 'Клики',
            color: '#10B981',
            dataKey: 'clicks',
            formatter: (value) => value.toLocaleString()
        },
        ctr: {
            label: 'CTR',
            color: '#F59E0B',
            dataKey: 'ctr',
            formatter: (value) => `${value.toFixed(2)}%`
        }
    };

    const formatTooltip = (value, name, props) => {
        const type = props.dataKey;
        return chartTypes[type].formatter(value);
    };

    const handleDateRangeChange = (newRange) => {
        if (newRange.startDate && newRange.endDate) {
            setDateRange({
                startDate: startOfDay(new Date(newRange.startDate)),
                endDate: startOfDay(new Date(newRange.endDate))
            });
        }
    };

    return (
        <AdminLayout>
            <div className="space-y-6">
                {/* Заголовок с выбором периода */}
                <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-semibold text-gray-900">Сводка</h1>
                    <DateRangePicker
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        onRangeChange={handleDateRangeChange}
                    />
                </div>

                {/* Карточки со статистикой */}
                <div className="bg-gray-50 p-6 rounded-xl">
                    <h2 className="text-lg font-medium text-gray-900 mb-4">Анализ данных</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <StatsCard
                            title="Показы за период"
                            value={stats.period.shows.toLocaleString()}
                            date={`${format(dateRange.startDate, 'dd.MM.yyyy')} — ${format(dateRange.endDate, 'dd.MM.yyyy')}`}
                        />
                        <StatsCard
                            title="Клики за период"
                            value={stats.period.clicks.toLocaleString()}
                            date={`${format(dateRange.startDate, 'dd.MM.yyyy')} — ${format(dateRange.endDate, 'dd.MM.yyyy')}`}
                        />
                        <StatsCard
                            title="CTR за период"
                            value={`${stats.period.ctr.toFixed(2)}%`}
                            date={`${format(dateRange.startDate, 'dd.MM.yyyy')} — ${format(dateRange.endDate, 'dd.MM.yyyy')}`}
                        />
                    </div>
                </div>

                {/* График */}
                <div className="bg-white p-6 rounded-xl shadow-sm">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
                        <h2 className="text-lg font-medium text-gray-900">
                            Статистика за период
                        </h2>
                        <div className="flex gap-2">
                            {Object.entries(chartTypes).map(([type, config]) => (
                                <button
                                    key={type}
                                    onClick={() => setChartType(type)}
                                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                                        chartType === type
                                            ? 'bg-indigo-100 text-indigo-600'
                                            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                    }`}
                                >
                                    {config.label}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="h-[400px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart 
                                data={stats.chartData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid 
                                    strokeDasharray="3 3" 
                                    stroke="#E5E7EB" 
                                    vertical={false}
                                />
                                <XAxis 
                                    dataKey="date" 
                                    stroke="#6B7280"
                                    fontSize={12}
                                    tickLine={false}
                                    axisLine={false}
                                    padding={{ left: 10, right: 10 }}
                                />
                                <YAxis 
                                    stroke="#6B7280"
                                    fontSize={12}
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={chartTypes[chartType].formatter}
                                />
                                <Tooltip 
                                    formatter={formatTooltip}
                                    contentStyle={{
                                        backgroundColor: 'white',
                                        border: '1px solid #E5E7EB',
                                        borderRadius: '6px',
                                        padding: '8px 12px'
                                    }}
                                    labelStyle={{
                                        color: '#6B7280',
                                        marginBottom: '4px'
                                    }}
                                />
                                <Line 
                                    type="monotone" 
                                    dataKey={chartTypes[chartType].dataKey}
                                    stroke={chartTypes[chartType].color}
                                    strokeWidth={2}
                                    dot={{
                                        r: 4,
                                        fill: chartTypes[chartType].color,
                                        strokeWidth: 2
                                    }}
                                    activeDot={{
                                        r: 6,
                                        stroke: chartTypes[chartType].color,
                                        strokeWidth: 2,
                                        fill: 'white'
                                    }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default Dashboard;