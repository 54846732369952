// src/admin/pages/News/NewsForm.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageCropModal from '../../components/ImageCrop/ImageCropModal';
import AdminLayout from '../../components/Layout';
import InputWrapper from '../../components/Forms/InputWrapper';
import ImageUpload from '../../components/Forms/ImageUpload';
import NewsPreview from '../../components/Preview/NewsPreview';
import { api, BASE_DOMAIN } from '../../utils/api';
import '../../../styles/NewsForm.css';

const PLACEHOLDER_TEXT = 'Добавьте текст, чтобы увидеть, как будет выглядеть блок на сайте';
const PLACEHOLDER_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNTAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2YzZjRmNiIvPjxwYXRoIGQ9Ik0xODUgMTQwaDEzMHYyMEgxODV6IiBmaWxsPSIjZDFkNWRiIi8+PHBhdGggZD0iTTIwMCAxNzBoMTAwdjEwSDIwMHoiIGZpbGw9IiNkMWQ1ZGIiLz48Y2lyY2xlIGN4PSIyNTAiIGN5PSI5MCIgcj0iMzAiIGZpbGw9IiNkMWQ1ZGIiLz48L3N2Zz4=';
const MAX_TITLE_LENGTH = 100;

const getCounterColor = (length, maxLength) => {
    const percentage = (length / maxLength) * 100;
    if (percentage < 50) return '#28a745';  // зеленый
    if (percentage < 75) return '#ffc107';  // желтый
    if (percentage < 90) return '#fd7e14';  // оранжевый
    return '#dc3545';  // красный
};

const NewsForm = ({ isEditing }) => {
    // State
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        title: '',
        sourceUrl: '',
        language: 'ru'
    });
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [tempImage, setTempImage] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({
        title: '',
        sourceUrl: '',
        image: ''
    });
    const [isPaused, setIsPaused] = useState(false);
    const [activeAspectRatio, setActiveAspectRatio] = useState('1:1');

    // Effects
    useEffect(() => {
        if (isEditing && id) {
            fetchNews();
        }
    }, [isEditing, id]);

    // API Calls
    const fetchNews = async () => {
        try {
            const response = await api.get(`/admin/news/${id}`);
            const news = response.data;
            setFormData({
                title: news.title,
                sourceUrl: news.sourceUrl,
                language: news.language
            });
            setCurrentImage(news.imageUrl);
            setIsPaused(!news.isActive);
        } catch (err) {
            setError('Ошибка при загрузке объявления');
            console.error(err);
        }
    };

    // Handlers
    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === 'title' && value.length > MAX_TITLE_LENGTH) {
            return;
        }
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    const handleImageChange = useCallback((e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {
            setError('Размер файла не должен превышать 5MB');
            return;
        }

        if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
            setError('Поддерживаются только форматы JPEG, PNG и WEBP');
            return;
        }

        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        
        img.onload = () => {
            if (img.width < 500 || img.height < 300) {
                setError('Изображение должно быть не меньше 500x300 пикселей');
                URL.revokeObjectURL(objectUrl);
                return;
            }
            
            setTempImage(objectUrl);
            setShowCropModal(true);
            setError('');
        };

        img.src = objectUrl;
    }, []);

    const handleCropComplete = useCallback((croppedImage) => {
        setImage(croppedImage);
        setPreview(URL.createObjectURL(croppedImage));
        setShowCropModal(false);
        setTempImage(null);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setFieldErrors({
            title: '',
            sourceUrl: '',
            image: ''
        });

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title.trim());
            formDataToSend.append('sourceUrl', formData.sourceUrl.trim());
            formDataToSend.append('language', formData.language);
            formDataToSend.append('isActive', !isPaused);
            
            if (image) {
                formDataToSend.append('image', image);
            }

            if (isEditing) {
                await api.put(`/admin/news/${id}`, formDataToSend);
            } else {
                await api.post('/admin/news', formDataToSend);
            }

            navigate('/admin/news');
        } catch (err) {
            if (err.errors) {
                setFieldErrors(err.errors);
            } else {
                setError(err.message || 'Произошла ошибка при сохранении новости');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageRemove = useCallback(() => {
        setPreview(null);
        setImage(null);
        setCurrentImage(null);
    }, []);

    // Render
    return (
        <AdminLayout>
            <div className="flex gap-10 p-5">
                {/* Form Section */}
                <div className="w-[500px]">
                    <h1 className="text-2xl text-blue-900 mb-8">
                        {isEditing ? 'Редактирование объявления' : 'Добавление объявления'}
                    </h1>
                    
                    <form onSubmit={handleSubmit}>
                        {/* Title Input */}
                        <div className="form-group">
                            <label className={`form-label ${fieldErrors.title ? 'form-label-error' : ''}`}>
                                Заголовок объявления
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    required
                                    maxLength={MAX_TITLE_LENGTH}
                                    placeholder="Введите заголовок объявления"
                                    className={`form-input ${fieldErrors.title ? 'error' : ''}`}
                                />
                                <div className="character-counter" style={{ color: getCounterColor(formData.title.length, MAX_TITLE_LENGTH) }}>
                                    Осталось символов: {MAX_TITLE_LENGTH - formData.title.length}
                                </div>
                            </div>
                        </div>

                        {/* Source URL Input */}
                        <InputWrapper label="URL источника" error={fieldErrors.sourceUrl}>
                            <input
                                type="url"
                                name="sourceUrl"
                                value={formData.sourceUrl}
                                onChange={handleInputChange}
                                required
                                placeholder="https://example.com"
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                            />
                        </InputWrapper>

                        {/* Language Select */}
                        <InputWrapper label="Язык">
                            <select
                                name="language"
                                value={formData.language}
                                onChange={handleInputChange}
                                className="w-40 p-2 border rounded"
                            >
                                <option value="ru">Русский</option>
                                <option value="pl">Польский</option>
                                <option value="ro">Румынский</option>
                            </select>
                        </InputWrapper>

                        {/* Image Upload */}
                        <InputWrapper 
                            label={isEditing ? 'Изображение объявления' : 'Изображение объявления *'} 
                            error={fieldErrors.image}
                        >
                            <ImageUpload
                                currentImage={currentImage}
                                preview={preview}
                                onImageChange={handleImageChange}
                                onImageRemove={handleImageRemove}
                                isRequired={!isEditing}
                                isEditing={isEditing}
                            />
                        </InputWrapper>

                        {/* Error Message */}
                        {error && (
                            <div className="p-3 mb-4 text-red-600 bg-red-50 border border-red-200 rounded">
                                <strong>Ошибка: </strong>{error}
                            </div>
                        )}

                        {/* Form Actions */}
                        <div className="flex items-center justify-between mt-8">
                            <label className="flex items-center gap-2 text-sm text-gray-600 cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={isPaused}
                                    onChange={(e) => setIsPaused(e.target.checked)}
                                    className="w-4 h-4 rounded border-gray-300"
                                />
                                <span>Не запускать объявление</span>
                            </label>

                            <div className="flex gap-3">
                                <button
                                    type="button"
                                    onClick={() => navigate('/admin/news')}
                                    className="px-6 py-2 text-gray-600 bg-white border rounded hover:bg-gray-50"
                                >
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="px-6 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-gray-400"
                                >
                                    {isLoading ? 'Сохранение...' : 'Сохранить'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                {/* Preview Section */}
                <div className="flex-1">
                    <h2 className="text-xl text-blue-900 mb-5">
                        Предпросмотр объявления
                    </h2>

                    <div className="max-w-xs">
                        <div className="flex gap-2 mb-2">
                            {['1:1', '3:2', '16:9'].map(ratio => (
                                <button
                                    key={ratio}
                                    onClick={() => setActiveAspectRatio(ratio)}
                                    className={`px-3 py-1 rounded ${
                                        activeAspectRatio === ratio 
                                            ? 'bg-blue-50 text-blue-600' 
                                            : 'bg-gray-100 text-gray-600'
                                    }`}
                                >
                                    {ratio}
                                </button>
                            ))}
                        </div>

                        <NewsPreview
                            image={preview || (currentImage ? `${BASE_DOMAIN}${currentImage}` : PLACEHOLDER_IMAGE)}
                            title={formData.title}
                            aspectRatio={activeAspectRatio}
                            placeholderText={PLACEHOLDER_TEXT}
                        />
                    </div>
                </div>
            </div>

            {/* Image Crop Modal */}
            {showCropModal && (
                <ImageCropModal
                    image={tempImage}
                    onComplete={handleCropComplete}
                    onCancel={() => {
                        setShowCropModal(false);
                        setTempImage(null);
                    }}
                />
            )}
        </AdminLayout>
    );
};

export default React.memo(NewsForm);