import React, { useState, useEffect, useCallback, useRef } from 'react';
import { newsAPI } from '../utils/api';
import api from '../utils/api';

// Функция для расчета веса новости на основе CTR
const calculateNewsWeight = (news) => {
    const currentDate = new Date();
    const dayStats = news.dailyStats?.[currentDate.toISOString().split('T')[0]] || {};
    const weekStats = news.weeklyStats || {};
    
    // Получаем статистику
    const currentViews = news.mainStats?.adsViews || 0;
    const currentClicks = news.mainStats?.adsClicks || 0;
    const dailyViews = dayStats.views || 0;
    const dailyClicks = dayStats.clicks || 0;
    const weeklyViews = weekStats.views || 0;
    const weeklyClicks = weekStats.clicks || 0;

    // Общее количество просмотров
    const totalViews = currentViews + weeklyViews;
    
    // Минимальное количество просмотров для достоверной статистики
    const MIN_VIEWS_THRESHOLD = 100;
    const LEARNING_PHASE_VIEWS = 1000;
    
    // Рассчитываем текущий CTR
    const currentCTR = currentViews > MIN_VIEWS_THRESHOLD ? 
        currentClicks / currentViews : 0;
    
    // Определяем бонус обучения на основе просмотров
    const learningBonus = Math.max(0, 0.2 * (1 - totalViews / LEARNING_PHASE_VIEWS));
    
    // Если просмотров мало, даём больше шансов для обучения
    if (totalViews < LEARNING_PHASE_VIEWS) {
        const newsDate = new Date(news.createdAt);
        const daysOld = (currentDate - newsDate) / (1000 * 60 * 60 * 24);
        
        // Для совсем новых новостей (до MIN_VIEWS_THRESHOLD просмотров) 
        // даём дополнительный бонус
        if (totalViews < MIN_VIEWS_THRESHOLD) {
            const initialBonus = 0.15 * (1 - totalViews / MIN_VIEWS_THRESHOLD);
            const freshnessBoost = Math.max(0, 0.1 - (daysOld * 0.01));
            return learningBonus + initialBonus + freshnessBoost;
        }
        
        // Для новостей в фазе обучения учитываем и CTR, и бонусы
        const freshnessBoost = Math.max(0, 0.1 - (daysOld * 0.01));
        return (currentCTR * 0.3) + learningBonus + freshnessBoost;
    }
    
    // Для новостей с достаточным количеством просмотров
    const dailyCTR = dailyViews > MIN_VIEWS_THRESHOLD ? 
        dailyClicks / dailyViews : 0;
    const weeklyCTR = weeklyViews > MIN_VIEWS_THRESHOLD ? 
        weeklyClicks / weeklyViews : 0;
    
    // Рассчитываем стабильность CTR и даём бонус за стабильно высокий CTR
    const isHighCTR = currentCTR > 0.05; // CTR выше 5%
    const isStable = Math.abs(currentCTR - weeklyCTR) < 0.1;
    const performanceBonus = (isHighCTR && isStable) ? 0.1 : 0;
    
    // Комбинируем CTR с разными весами
    const combinedCTR = (currentCTR * 0.5) + 
                       (dailyCTR * 0.3) + 
                       (weeklyCTR * 0.2) + 
                       performanceBonus;
    
    return combinedCTR;
};

// Функция для сортировки с элементом случайности
const weightedShuffle = (array) => {
    const weighted = array.map(news => ({
        ...news,
        weight: calculateNewsWeight(news) + Math.random() * 0.3 // Добавляем случайность
    }));
    
    // Сортируем по весу
    weighted.sort((a, b) => b.weight - a.weight);
    
    // Возвращаем исходные объекты новостей без веса
    return weighted.map(item => ({
        ...item,
        weight: undefined
    }));
};

const RelatedNews = ({ 
    currentNewsId, 
    currentLanguage, 
    isMobileTop = false,
    itemsCount = null 
}) => {
    const [originalNews, setOriginalNews] = useState([]);
    const [displayedNews, setDisplayedNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currentNewsLanguage, setCurrentNewsLanguage] = useState(null);
    const viewedNews = useRef(new Set());
    const observerRef = useRef(null);
    const loadingRef = useRef(null);
    const ITEMS_PER_PAGE = 11;
    const MAX_PAGES = 7;

    useEffect(() => {
        if (currentLanguage) {
            setCurrentNewsLanguage(currentLanguage);
            setDisplayedNews([]);
            setCurrentPage(1);
            setHasMore(true);
        }
    }, [currentLanguage]);

    // Изменяем загрузку начальных новостей
    useEffect(() => {
        const fetchRelatedNews = async () => {
            if (!currentLanguage) return;

            try {
                const response = await newsAPI.getNews(currentLanguage);
                
                if (!Array.isArray(response.data)) return;
                
                const filteredNews = response.data
                    .filter(news => 
                        news._id !== currentNewsId && 
                        news.isActive && 
                        news.language === currentLanguage
                    )
                    .map(news => ({
                        ...news,
                        image_url: news.imageUrl.startsWith('http') 
                            ? news.imageUrl 
                            : `${window.location.origin}${news.imageUrl}`
                    }));

                const weightedNews = weightedShuffle([...filteredNews]);
                
                setOriginalNews(weightedNews);
                
                // Разделяем логику для мобильного топа и основного списка
                if (isMobileTop) {
                    const topItems = weightedNews.slice(0, itemsCount || 1);
                    setDisplayedNews(topItems);
                    setHasMore(false); // Для мобильного топа отключаем подгрузку
                } else {
                    const initialItems = ITEMS_PER_PAGE;
                    setDisplayedNews(weightedNews.slice(0, initialItems));
                    setHasMore(weightedNews.length > initialItems);
                }
                
            } catch (error) {
                // Тихая обработка ошибок
            }
        };

        if (currentNewsId && currentLanguage) {
            fetchRelatedNews();
        }
    }, [currentNewsId, currentLanguage, isMobileTop, itemsCount]);

    // Изменяем логику подгрузки новостей
    const loadMoreNews = useCallback(() => {
        if (currentPage >= MAX_PAGES) {
            setHasMore(false);
            return;
        }

        const startIndex = currentPage * ITEMS_PER_PAGE;
        let nextNews = [];

        if (startIndex < originalNews.length) {
            // Берем следующую порцию из оригинального массива
            nextNews = originalNews.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        } else {
            // Для повторных новостей исключаем текущий currentNewsId
            const availableNews = originalNews.filter(news => news._id !== currentNewsId);
            const repeatedNewsPool = weightedShuffle([...availableNews]);
            
            nextNews = repeatedNewsPool
                .slice(0, ITEMS_PER_PAGE)
                .map(news => ({
                    ...news,
                    _id: `${news._id}-repeat-${currentPage}`
                }));
        }

        if (nextNews.length > 0) {
            setDisplayedNews(prev => [...prev, ...nextNews]);
            setCurrentPage(prev => prev + 1);
            setHasMore(currentPage + 1 < MAX_PAGES);
        }
    }, [currentPage, originalNews, currentNewsId]);

    // Наблюдатель за скроллом
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    loadMoreNews();
                }
            },
            { threshold: 0.1 }
        );

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => observer.disconnect();
    }, [loadMoreNews, hasMore]);

    // Регистрация показов
    useEffect(() => {
        // Создаем новый наблюдатель
        observerRef.current = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const newsId = entry.target.dataset.newsId;
                        if (newsId && !viewedNews.current.has(newsId)) {
                            viewedNews.current.add(newsId);
                            const originalId = newsId.split('-repeat-')[0];
                            
                            (async () => {
                                try {
                                    await newsAPI.registerAdView(originalId);
                                } catch (error) {
                                    viewedNews.current.delete(newsId);
                                }
                            })();
                        }
                    }
                });
            },
            { threshold: 0.5 }
        );

        // Находим все элементы новостей и начинаем наблюдение за ними
        const newsElements = document.querySelectorAll('.news-item');
        newsElements.forEach(element => {
            if (observerRef.current) {
                observerRef.current.observe(element);
            }
        });

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [displayedNews]);

    // Обработка клика
    const handleClick = useCallback(async (newsId, slug, event) => {
        event.preventDefault();
        event.stopPropagation();
        
        if (!newsId || !slug) return;

        const originalId = newsId.split('-repeat-')[0]; // Получаем оригинальный ID
        const controller = new AbortController();

        try {
            await newsAPI.registerAdClick(originalId);
            const response = await newsAPI.getAdRedirectUrl(slug);
            
            if (response.data && response.data.redirectUrl) {
                const link = document.createElement('a');
                link.href = response.data.redirectUrl;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                controller.abort();
                return true;
            }
        } catch (error) {
            if (error.name === 'CanceledError') {
                return true;
            }
            
            const news = originalNews.find(n => n._id === originalId);
            if (news?.sourceUrl) {
                let url = news.sourceUrl;
                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    url = 'https://' + url;
                }
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                return true;
            }
        }
        return false;
    }, [originalNews]);

    const renderNewsItem = useCallback((news, isLarge = false) => {
        if (!news._id) return null;

        const redirectUrl = `/out/news/${news.slug}`;

        return (
            <div 
                className={`item item-t ${isLarge ? 'item-lg' : 'item-sm'} news-item`}
                key={news._id}
                data-news-id={news._id} // Убеждаемся, что ID установлен правильно
            >
                <div className="teaser">
                    <a 
                        href={redirectUrl}  // Используем redirectUrl вместо прямого указания пути
                        onClick={(e) => handleClick(news._id, news.slug, e)}
                        className="dynamic-load"
                        rel="noopener noreferrer"
                    >
                        {/* содержимое ссылки */}
                    </a>
                    <div className="item-overlay"></div>
                    <div className="item-overlay-blur">
                        <img 
                            src={news.image_url} 
                            alt={news.title}
                            loading="lazy"
                        />
                    </div>
                    <div className="item-image">
                        <img 
                            src={news.image_url} 
                            alt={news.title}
                            loading="lazy"
                        />
                    </div>
                    <div className="item-title" data-slug={news.slug} data-id={news._id}>
                        {news.title}
                    </div>
                </div>
            </div>
        );
    }, [handleClick]);

    // Удаляем условие, которое возвращает только стили
    if (isMobileTop) {
        return (
            <>
                {displayedNews.map((news, index) => renderNewsItem(news, (index % 6 >= 4)))}
            </>
        );
    }

    if (!originalNews.length) return null;

    return (
        <>
            {displayedNews.map((news, index) => renderNewsItem(news, (index % 6 >= 4)))}
            {hasMore && <div ref={loadingRef} style={{ height: '20px', margin: '10px 0' }} />}
        </>
    );
};

export default RelatedNews;