import React, { useState, useEffect } from 'react';
import { api } from '../../utils/api';

const StatsOverview = () => {
    const [stats, setStats] = useState({
        total: { active: 0, inactive: 0 },
        byLanguage: {
            ru: { active: 0, inactive: 0 },
            pl: { active: 0, inactive: 0 },
            ro: { active: 0, inactive: 0 }
        }
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/admin/news', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const news = response.data.news || [];
                const newStats = {
                    total: { active: 0, inactive: 0 },
                    byLanguage: {
                        ru: { active: 0, inactive: 0 },
                        pl: { active: 0, inactive: 0 },
                        ro: { active: 0, inactive: 0 }
                    }
                };

                news.forEach(item => {
                    if (item.isActive) {
                        newStats.total.active++;
                    } else {
                        newStats.total.inactive++;
                    }

                    if (newStats.byLanguage[item.language]) {
                        if (item.isActive) {
                            newStats.byLanguage[item.language].active++;
                        } else {
                            newStats.byLanguage[item.language].inactive++;
                        }
                    }
                });

                setStats(newStats);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching stats:', error);
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    const StatBox = ({ title, active, inactive }) => (
        <div style={{
            padding: '15px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            marginBottom: '10px',
            borderLeft: '4px solid #304878'
        }}>
            <div style={{
                fontSize: '14px',
                color: '#304878',
                fontWeight: '600',
                marginBottom: '10px'
            }}>
                {title}
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '8px'
            }}>
                <div style={{
                    flex: 1,
                    backgroundColor: '#e3fcef',
                    padding: '8px',
                    borderRadius: '6px'
                }}>
                    <div style={{
                        fontSize: '11px',
                        color: '#0d9488',
                        marginBottom: '4px'
                    }}>
                        Активные
                    </div>
                    <div style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#0d9488'
                    }}>
                        {active}
                    </div>
                </div>
                <div style={{
                    flex: 1,
                    backgroundColor: '#ffe5e5',
                    padding: '8px',
                    borderRadius: '6px'
                }}>
                    <div style={{
                        fontSize: '11px',
                        color: '#dc2626',
                        marginBottom: '4px'
                    }}>
                        Неактивные
                    </div>
                    <div style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#dc2626'
                    }}>
                        {inactive}
                    </div>
                </div>
            </div>
        </div>
    );

    if (loading) {
        return <div style={{
            padding: '20px',
            textAlign: 'center',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
        }}>
            Загрузка статистики...
        </div>;
    }

    return (
        <div style={{
            backgroundColor: '#f8fafc',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
        }}>
            <div style={{ 
                backgroundColor: '#304878',
                color: 'white',
                padding: '12px 16px',
                fontSize: '15px',
                fontWeight: '600',
                letterSpacing: '0.5px',
                borderRadius: '6px',
                marginBottom: '16px'
            }}>
                Количество новостей
            </div>
            <div style={{ padding: '0' }}>
                <StatBox 
                    title="Все новости"
                    active={stats.total.active}
                    inactive={stats.total.inactive}
                />
                <div style={{ marginTop: '20px' }}>
                    <StatBox 
                        title="Русский язык"
                        active={stats.byLanguage.ru.active}
                        inactive={stats.byLanguage.ru.inactive}
                    />
                    <StatBox 
                        title="Польский язык"
                        active={stats.byLanguage.pl.active}
                        inactive={stats.byLanguage.pl.inactive}
                    />
                    <StatBox 
                        title="Румынский язык"
                        active={stats.byLanguage.ro.active}
                        inactive={stats.byLanguage.ro.inactive}
                    />
                </div>
            </div>
        </div>
    );
};

export default StatsOverview;