import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format, subDays, eachDayOfInterval } from 'date-fns';
import { ru } from 'date-fns/locale';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';

const NewsStats = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [news, setNews] = useState(null);
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        const sevenDaysAgo = subDays(today, 6); // для последних 7 дней
        return {
            startDate: new Date(Date.UTC(
                sevenDaysAgo.getFullYear(),
                sevenDaysAgo.getMonth(),
                sevenDaysAgo.getDate(),
                0, 0, 0, 0
            )),
            endDate: new Date(Date.UTC(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                23, 59, 59, 999
            ))
        };
    });

    useEffect(() => {
        fetchNewsDetails();
    }, [id]);

    const fetchNewsDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/admin');
                return;
            }

            const response = await axios.get(`/api/admin/news/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setNews(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching news details:', error);
            setError('Ошибка при загрузке данных объявления');
            setLoading(false);
            if (error.response?.status === 401) {
                navigate('/admin');
            }
        }
    };

    const handleStatsChange = async (newStats, dateRange) => {
        console.log('Date range changed:', dateRange);
        setSelectedDate(dateRange);
        // Не устанавливаем здесь статистику, так как она будет загружена в useEffect
    };

    // Единый метод загрузки статистики
    useEffect(() => {
        if (!loading && news && selectedDate) {
            const fetchStats = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`/api/admin/stats/news/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        params: {
                            startDate: format(selectedDate.startDate, 'yyyy-MM-dd'),
                            endDate: format(selectedDate.endDate, 'yyyy-MM-dd')
                        }
                    });

                    console.log('Полученная статистика:', response.data);

                    if (response.data && Array.isArray(response.data.stats)) {
                        // Получаем все даты в выбранном периоде
                        const dateRange = eachDayOfInterval({
                            start: selectedDate.startDate,
                            end: selectedDate.endDate
                        });

                        // Создаем Map существующей статистики
                        const statsMap = new Map(
                            response.data.stats.map(stat => [
                                format(new Date(stat.date), 'yyyy-MM-dd'),
                                stat
                            ])
                        );

                        // Создаем полный список статистики для всех дат
                        const fullStats = dateRange.map(date => {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            const existingStat = statsMap.get(formattedDate);

                            if (existingStat) {
                                return {
                                    ...existingStat,
                                    date: new Date(date)
                                };
                            }

                            // Если статистики нет - создаем пустую запись
                            return {
                                _id: `empty-${formattedDate}`,
                                date: new Date(date),
                                stats: {
                                    traffic: {
                                        views: 0,
                                        clicks: 0,
                                        ctr: 0
                                    },
                                    ads: {
                                        views: 0,
                                        clicks: 0,
                                        ctr: 0
                                    }
                                }
                            };
                        });

                        // Сортируем по дате (новые сверху)
                        const sortedStats = fullStats.sort((a, b) => 
                            new Date(b.date) - new Date(a.date)
                        );

                        console.log('Stats processing:', {
                            dateRange: dateRange.length,
                            receivedStats: response.data.stats.length,
                            processedStats: sortedStats.length,
                            statsMap: Array.from(statsMap.entries())
                        });

                        setStats(sortedStats);
                    }
                } catch (error) {
                    console.error('Error fetching stats:', error);
                    if (error.response?.status === 401) {
                        navigate('/admin');
                    }
                }
            };

            fetchStats();
        }
    }, [id, loading, news, selectedDate]);

    // Изменить отображение статистики
    const renderStats = (news) => {
        return {
            views: news.adsStats?.views || 0,
            clicks: news.adsStats?.clicks || 0,
            ctr: news.adsStats?.ctr?.toFixed(2) || '0.00'
        };
    };

    if (loading) return <div className="p-6">Загрузка...</div>;
    if (error) return <div className="p-6 text-red-500">Ошибка: {error}</div>;

    return (
        <div className="flex h-screen bg-gray-100">
            <AdminSidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
                    <div className="px-6 py-8">
                        <div className="flex justify-between items-start mb-6">
                            <div>
                                <h1 className="text-2xl font-bold text-gray-900 mb-1">
                                    Статистика объявления
                                </h1>
                                {news && (
                                    <div className="text-sm text-gray-600">
                                        ID: {news.orderNumber} | {news.title}
                                    </div>
                                )}
                            </div>
                            <div className="ml-4">
                                <DateRangePicker 
                                    onStatsChange={handleStatsChange}
                                    defaultPeriod="last7days"
                                />
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow overflow-hidden">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                Дата
                                            </th>
                                            <th scope="col" className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                Показы
                                            </th>
                                            <th scope="col" className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                Клики
                                            </th>
                                            <th scope="col" className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                CTR
                                            </th>
                                            <th scope="col" className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                Показы (реклама)
                                            </th>
                                            <th scope="col" className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                Клики (реклама)
                                            </th>
                                            <th scope="col" className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                CTR (реклама)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {stats.map(stat => {
                                            const statDate = new Date(stat.date);
                                            return (
                                                <tr key={stat._id} className="hover:bg-gray-50">
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        {format(statDate, 'dd.MM.yyyy')}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                        {stat.stats.traffic.views.toLocaleString()}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                        {stat.stats.traffic.clicks.toLocaleString()}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                        {stat.stats.traffic.ctr.toFixed(2)}%
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                        {stat.stats.ads.views.toLocaleString()}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                        {stat.stats.ads.clicks.toLocaleString()}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                                                        {stat.stats.ads.ctr.toFixed(2)}%
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default NewsStats; 