import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BuildIcon from '@mui/icons-material/Build';

const AdminSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(() => {
        const saved = localStorage.getItem('sidebarCollapsed');
        return saved ? JSON.parse(saved) : false;
    });

    useEffect(() => {
        localStorage.setItem('sidebarCollapsed', JSON.stringify(isCollapsed));
    }, [isCollapsed]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/admin');
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const menuItems = [
        {
            path: '/admin/dashboard',
            icon: <DashboardIcon />,
            label: 'Дашборд'
        },
        {
            path: '/admin/news',
            icon: <NewspaperIcon />,
            label: 'Объявления'
        },
        {
            path: '/admin/tools',
            icon: <BuildIcon />,
            label: 'Инструменты'
        }
    ];

    const isActive = (path) => location.pathname === path;

    return (
        <div className={`min-h-screen bg-white border-r border-gray-200 transition-all duration-300 ${
            isCollapsed ? 'w-20' : 'w-64'
        }`}>
            {/* Логотип и кнопка сворачивания */}
            <div className="h-16 flex items-center justify-between px-6 border-b border-gray-200">
                {!isCollapsed && (
                    <span className="text-lg font-semibold text-gray-900">
                        Админ панель
                    </span>
                )}
                <button 
                    onClick={toggleSidebar}
                    className={`
                        flex items-center justify-center
                        ${isCollapsed ? 'w-8 mx-auto' : 'ml-auto'} 
                        h-8
                        border border-gray-200 rounded-lg
                        hover:bg-gray-50 transition-all
                        group
                    `}
                >
                    <div className="flex items-center">
                        {isCollapsed ? (
                            <>
                                <KeyboardArrowRightIcon />
                                <KeyboardArrowRightIcon className="-ml-4 text-indigo-600" />
                            </>
                        ) : (
                            <>
                                <KeyboardArrowLeftIcon />
                                <KeyboardArrowLeftIcon className="-ml-4 text-indigo-600" />
                            </>
                        )}
                    </div>
                </button>
            </div>

            {/* Навигация */}
            <nav className="flex flex-col gap-1 px-4 py-6">
                {menuItems.map((item) => (
                    <Link
                        key={item.path}
                        to={item.path}
                        className={`
                            flex items-center gap-3 px-4 py-3 
                            text-sm font-medium rounded-lg transition-colors
                            ${isCollapsed ? 'justify-center px-2' : ''}
                            ${isActive(item.path)
                                ? 'bg-indigo-50 text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50'
                            }
                        `}
                    >
                        <div className={`
                            flex items-center justify-center 
                            ${isActive(item.path) ? 'text-indigo-600' : 'text-gray-500'}
                        `}>
                            {item.icon}
                        </div>
                        {!isCollapsed && <span>{item.label}</span>}
                    </Link>
                ))}
            </nav>

            {/* Кнопка выхода */}
            <div className={`absolute bottom-0 border-t border-gray-200 ${
                isCollapsed ? 'w-20' : 'w-64'
            }`}>
                <button
                    onClick={handleLogout}
                    className={`flex items-center gap-3 px-6 py-4 w-full text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors ${
                        isCollapsed ? 'justify-center px-2' : ''
                    }`}
                >
                    <LogoutIcon className="text-gray-500" />
                    {!isCollapsed && 'Выйти'}
                </button>
            </div>
        </div>
    );
};

export default AdminSidebar;