import React from 'react';
import { buttonStyles, imageStyles } from './ImageUpload.styles';
import { api, BASE_DOMAIN } from '../../utils/api';

const ImageUpload = ({ 
    currentImage, 
    preview, 
    onImageChange, 
    onImageRemove, 
    isRequired,
    isEditing,
}) => {
    return (
        <div style={imageStyles.container}>
            {(preview || currentImage) && (
                <div style={imageStyles.preview}>
                    <img 
                        src={preview || (currentImage ? `${BASE_DOMAIN}${currentImage}` : '')}
                        alt="Preview" 
                        style={imageStyles.previewImage}
                    />
                    <button
                        type="button"
                        onClick={onImageRemove}
                        style={buttonStyles.removeButton}
                    >
                        ✕
                    </button>
                </div>
            )}
            
            <div style={imageStyles.uploadContainer}>
                <label
                    style={buttonStyles.uploadButton}
                    onMouseOver={e => e.target.style.backgroundColor = '#1e3c72'}
                    onMouseOut={e => e.target.style.backgroundColor = '#2a5298'}
                >
                    <input
                        type="file"
                        accept="image/jpeg,image/png,image/webp"
                        onChange={onImageChange}
                        required={!isEditing && isRequired}
                        style={{ display: 'none' }}
                    />
                    {preview || currentImage ? 'Изменить изображение' : 'Выбрать изображение'}
                </label>
                
                {!preview && !currentImage && (
                    <div style={imageStyles.helpText}>
                        Размер изображения не менее 500x300 px. Формат: JPG, PNG, WEBP
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUpload;