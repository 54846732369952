import React, { useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BarChartIcon from '@mui/icons-material/BarChart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { BASE_DOMAIN } from '../../utils/api';
import { newsTitleStyles, newsTableRowStyles, tableStyles } from '../../pages/News/NewsList.styles';
import { isSameDay } from 'date-fns';
import DateRangePicker from '../DateRangePicker/DateRangePicker';

const NewsTitle = ({ title, sourceUrl }) => {
    return (
        <div style={newsTitleStyles.container}>
            <div style={newsTitleStyles.title}>{title}</div>
            <div style={newsTitleStyles.urlContainer}>
                <a 
                    href={sourceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={newsTitleStyles.url}
                >
                    {sourceUrl}
                </a>
            </div>
        </div>
    );
};

const SortArrows = ({ sortField, currentField, sortDirection }) => {
    if (sortField !== currentField) {
        return (
            <div style={tableStyles.sortArrows}>
                <span style={tableStyles.sortArrow}>▲</span>
                <span style={tableStyles.sortArrow}>▼</span>
            </div>
        );
    }
    return (
        <span style={{
            ...tableStyles.sortArrow,
            color: '#2a5298'
        }}>
            {sortDirection === 'asc' ? '▲' : '▼'}
        </span>
    );
};

export const NewsTableHeaderColumns = ({ 
    handleSelectAll, 
    filteredAndSortedNews, 
    selectedItems, 
    tableStyles, 
    handleSort, 
    sortField, 
    sortDirection,
    allNews,
    historicalStats,
    selectedDate,
    forceUpdate,
    onTotalsCalculated
}) => {
    useEffect(() => {
        console.log('NewsTableHeaderColumns received:', {
            filteredCount: filteredAndSortedNews?.length,
            allNewsCount: allNews?.length,
            historicalStatsCount: historicalStats?.length
        });
    }, [filteredAndSortedNews, allNews, historicalStats]);

    const totals = useMemo(() => {
        if (!filteredAndSortedNews?.length) {
            return { views: 0, clicks: 0, adsViews: 0, adsClicks: 0 };
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (selectedDate) {
            const isEndToday = isSameDay(selectedDate.endDate, today);

            const historicalTotals = (historicalStats || []).reduce((acc, stat) => {
                const isInFilteredList = filteredAndSortedNews.some(
                    news => news._id.toString() === stat.advertisementId.toString()
                );
                
                if (!isInFilteredList) return acc;

                const statDate = new Date(stat.date);
                if (isEndToday && isSameDay(statDate, today)) {
                    return acc;
                }
                return {
                    views: acc.views + (stat.stats?.traffic?.views || 0),
                    clicks: acc.clicks + (stat.stats?.traffic?.clicks || 0),
                    adsViews: acc.adsViews + (stat.stats?.ads?.views || 0),
                    adsClicks: acc.adsClicks + (stat.stats?.ads?.clicks || 0)
                };
            }, { views: 0, clicks: 0, adsViews: 0, adsClicks: 0 });

            if (isEndToday) {
                const currentTotals = filteredAndSortedNews.reduce((acc, item) => ({
                    views: acc.views + (item.stats?.views || 0),
                    clicks: acc.clicks + (item.stats?.clicks || 0),
                    adsViews: acc.adsViews + (item.adsStats?.views || 0),
                    adsClicks: acc.adsClicks + (item.adsStats?.clicks || 0)
                }), { views: 0, clicks: 0, adsViews: 0, adsClicks: 0 });

                return {
                    views: historicalTotals.views + currentTotals.views,
                    clicks: historicalTotals.clicks + currentTotals.clicks,
                    adsViews: historicalTotals.adsViews + currentTotals.adsViews,
                    adsClicks: historicalTotals.adsClicks + currentTotals.adsClicks
                };
            }

            return historicalTotals;
        }

        const result = filteredAndSortedNews.reduce((acc, item) => ({
            views: acc.views + (item.stats?.views || 0),
            clicks: acc.clicks + (item.stats?.clicks || 0),
            adsViews: acc.adsViews + (item.adsStats?.views || 0),
            adsClicks: acc.adsClicks + (item.adsStats?.clicks || 0)
        }), { views: 0, clicks: 0, adsViews: 0, adsClicks: 0 });

        if (onTotalsCalculated) {
            onTotalsCalculated(result);
        }

        return result;
    }, [filteredAndSortedNews, historicalStats, selectedDate, onTotalsCalculated]);

    const avgCtr = totals.views > 0 ? ((totals.clicks / totals.views) * 100).toFixed(2) : '0.00';
    const avgAdsCtr = totals.adsViews > 0 ? ((totals.adsClicks / totals.adsViews) * 100).toFixed(2) : '0.00';

    return (
        <>
            <thead>
                <tr>
                    <th style={{ 
                        ...tableStyles.header, 
                        width: '40px', 
                        textAlign: 'center',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} rowSpan="2">
                        <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedItems.size === filteredAndSortedNews.length}
                            style={tableStyles.checkbox}
                        />
                    </th>
                    <th style={{ 
                        ...tableStyles.header, 
                        width: '280px',
                        minWidth: '280px',
                        textAlign: 'center',
                        padding: '12px 8px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} rowSpan="2">Управление</th>
                    <th style={{ 
                        ...tableStyles.header, 
                        width: '100%',
                        textAlign: 'center',
                        minWidth: '400px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} rowSpan="2">Объявление</th>
                    <th style={{ 
                        ...tableStyles.header, 
                        width: '280px', 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} colSpan="3">ТРАФИК</th>
                    <th style={{ 
                        ...tableStyles.header, 
                        width: '280px', 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} colSpan="3">РЕКЛАМА</th>
                </tr>
                <tr>
                    <th style={{ 
                        ...tableStyles.header, 
                        ...tableStyles.sortableHeader, 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} onClick={() => handleSort('views')}>
                        <div style={tableStyles.headerContent}>
                            Показы
                            <SortArrows 
                                sortField={sortField} 
                                currentField="views" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th style={{ 
                        ...tableStyles.header, 
                        ...tableStyles.sortableHeader, 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} onClick={() => handleSort('clicks')}>
                        <div style={tableStyles.headerContent}>
                            Клики
                            <SortArrows 
                                sortField={sortField} 
                                currentField="clicks" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th style={{ 
                        ...tableStyles.header, 
                        ...tableStyles.sortableHeader, 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} onClick={() => handleSort('ctr')}>
                        <div style={tableStyles.headerContent}>
                            <span>CTR, %</span>
                            <SortArrows 
                                sortField={sortField} 
                                currentField="ctr" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th style={{ 
                        ...tableStyles.header, 
                        ...tableStyles.sortableHeader, 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} onClick={() => handleSort('adsViews')}>
                        <div style={tableStyles.headerContent}>
                            Показы
                            <SortArrows 
                                sortField={sortField} 
                                currentField="adsViews" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th style={{ 
                        ...tableStyles.header, 
                        ...tableStyles.sortableHeader, 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} onClick={() => handleSort('adsClicks')}>
                        <div style={tableStyles.headerContent}>
                            Клики
                            <SortArrows 
                                sortField={sortField} 
                                currentField="adsClicks" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th style={{ 
                        ...tableStyles.header, 
                        ...tableStyles.sortableHeader, 
                        textAlign: 'center',
                        padding: '12px 24px',
                        borderRight: '1px solid #e2e8f0',
                        borderBottom: '1px solid #e2e8f0'
                    }} onClick={() => handleSort('adsCtr')}>
                        <div style={tableStyles.headerContent}>
                            <span>CTR, %</span>
                            <SortArrows 
                                sortField={sortField} 
                                currentField="adsCtr" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td colSpan="3" style={{ 
                        ...tableStyles.cell, 
                        textAlign: 'right', 
                        fontWeight: '600',
                        borderBottom: '1px solid #e2e8f0',
                        borderRight: '1px solid #e2e8f0',
                        backgroundColor: '#f8fafc'
                    }}>
                        Итого:
                    </td>
                    <td style={{ 
                        ...tableStyles.cell, 
                        textAlign: 'center', 
                        fontWeight: '600',
                        borderBottom: '1px solid #e2e8f0',
                        borderRight: '1px solid #e2e8f0',
                        backgroundColor: '#f8fafc'
                    }}>
                        {totals.views}
                    </td>
                    <td style={{ 
                        ...tableStyles.cell, 
                        textAlign: 'center', 
                        fontWeight: '600',
                        borderBottom: '1px solid #e2e8f0',
                        borderRight: '1px solid #e2e8f0',
                        backgroundColor: '#f8fafc'
                    }}>
                        {totals.clicks}
                    </td>
                    <td style={{ 
                        ...tableStyles.cell, 
                        textAlign: 'center', 
                        fontWeight: '600',
                        borderBottom: '1px solid #e2e8f0',
                        borderRight: '1px solid #e2e8f0',
                        backgroundColor: '#f8fafc'
                    }}>
                        {avgCtr}%
                    </td>
                    <td style={{ 
                        ...tableStyles.cell, 
                        textAlign: 'center', 
                        fontWeight: '600',
                        borderBottom: '1px solid #e2e8f0',
                        borderRight: '1px solid #e2e8f0',
                        backgroundColor: '#f8fafc'
                    }}>
                        {totals.adsViews}
                    </td>
                    <td style={{ 
                        ...tableStyles.cell, 
                        textAlign: 'center', 
                        fontWeight: '600',
                        borderBottom: '1px solid #e2e8f0',
                        borderRight: '1px solid #e2e8f0',
                        backgroundColor: '#f8fafc'
                    }}>
                        {totals.adsClicks}
                    </td>
                    <td style={{ 
                        ...tableStyles.cell, 
                        textAlign: 'center', 
                        fontWeight: '600',
                        borderBottom: '1px solid #e2e8f0',
                        borderRight: '1px solid #e2e8f0',
                        backgroundColor: '#f8fafc'
                    }}>
                        {avgAdsCtr}%
                    </td>
                </tr>
            </tfoot>
        </>
    );
};

// Функция для форматирования статистики
const formatStats = (rawStats) => {
    if (!rawStats) {
        return {
            traffic: {
                views: 0,
                clicks: 0,
                ctr: 0
            },
            ads: {
                views: 0,
                clicks: 0,
                ctr: 0
            }
        };
    }

    return {
        traffic: {
            views: rawStats?.traffic?.views || 0,
            clicks: rawStats?.traffic?.clicks || 0,
            ctr: rawStats?.traffic?.ctr || 0
        },
        ads: {
            views: rawStats?.ads?.views || 0,
            clicks: rawStats?.ads?.clicks || 0,
            ctr: rawStats?.ads?.ctr || 0
        }
    };
};

const NewsTableRow = ({
    item,
    historicalStats,
    selectedDate,
    selectedItems,
    handleSelectItem,
    handleToggleActive,
    handleDelete,
    showMenu,
    setShowMenu,
    getLanguageName
}) => {
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowMenu]);

    useEffect(() => {
        console.log('NewsTableRow props updated:', {
            itemId: item._id,
            historicalStatsLength: historicalStats?.length,
            historicalStats
        });
    }, [item._id, historicalStats]);

    const handleOpenUrl = (e) => {
        e.stopPropagation();
        if (item.slug) {
            const win = window.open(`/news/${item.slug}`, '_blank');
            if (win) {
                win.focus();
            }
        }
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        const confirmed = window.confirm('Вы уверены, что хотите удалить эту новость?');
        if (confirmed) {
            handleDelete(item._id);
        }
    };

    // Добавим проверку при получении пропсов
    console.log('NewsTableRow received:', {
        itemId: item._id,
        historicalStats: historicalStats
    });

    // Получаем статистику
    const stats = useMemo(() => {
        console.log('Calculating stats for item:', {
            itemId: item._id,
            historicalStatsLength: historicalStats?.length,
            selectedDate: selectedDate ? {
                start: selectedDate.startDate.toISOString(),
                end: selectedDate.endDate.toISOString()
            } : null
        });

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Если это текущий день - показываем текущую статистику
        if (selectedDate?.endDate && isSameDay(selectedDate.endDate, today)) {
            const historicalStats = calculateHistoricalStats();
            const currentStats = getCurrentStats();
            return combineStats(historicalStats, currentStats);
        }

        // Если выбран период - суммируем статистику за все дни
        if (selectedDate) {
            return calculatePeriodStats();
        }

        // Если нет выбранной даты - показываем текущую статистику
        return getCurrentStats();

        // Вспомогательные функции
        function getCurrentStats() {
            // Если выбран текущий день, берем актуальную статистику
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            if (selectedDate?.endDate && isSameDay(selectedDate.endDate, today)) {
                return formatStats({
                    traffic: {
                        views: item.stats?.views || 0,
                        clicks: item.stats?.clicks || 0,
                        ctr: calculateCTR(item.stats?.views, item.stats?.clicks)
                    },
                    ads: {
                        views: item.adsStats?.views || 0,
                        clicks: item.adsStats?.clicks || 0,
                        ctr: calculateCTR(item.adsStats?.views, item.adsStats?.clicks)
                    }
                });
            }

            // Для других дней возвращаем пустую статистику
            return formatStats(null);
        }

        function calculateHistoricalStats() {
            if (!historicalStats?.length) return formatStats(null);

            return historicalStats
                .filter(stat => {
                    if (!stat?.advertisementId) return false;
                    const itemIdString = item._id.toString();
                    const statIdString = stat.advertisementId.toString();
                    const isMatch = itemIdString === statIdString;
                    const statDate = new Date(stat.date);
                    return isMatch && 
                           statDate >= selectedDate.startDate && 
                           statDate < today; // Исключаем текущий день
                })
                .reduce((acc, stat) => {
                    const statData = formatStats(stat.stats);
                    return {
                        traffic: {
                            views: acc.traffic.views + statData.traffic.views,
                            clicks: acc.traffic.clicks + statData.traffic.clicks,
                            ctr: calculateCTR(acc.traffic.views + statData.traffic.views, 
                                            acc.traffic.clicks + statData.traffic.clicks)
                        },
                        ads: {
                            views: acc.ads.views + statData.ads.views,
                            clicks: acc.ads.clicks + statData.ads.clicks,
                            ctr: calculateCTR(acc.ads.views + statData.ads.views, 
                                            acc.ads.clicks + statData.ads.clicks)
                        }
                    };
                }, formatStats(null));
        }

        function calculatePeriodStats() {
            if (!historicalStats?.length) return formatStats(null);

            return historicalStats
                .filter(stat => {
                    if (!stat?.advertisementId) return false;
                    const itemIdString = item._id.toString();
                    const statIdString = stat.advertisementId.toString();
                    const isMatch = itemIdString === statIdString;
                    const statDate = new Date(stat.date);
                    return isMatch && 
                           statDate >= selectedDate.startDate && 
                           statDate <= selectedDate.endDate;
                })
                .reduce((acc, stat) => {
                    const statData = formatStats(stat.stats);
                    return {
                        traffic: {
                            views: acc.traffic.views + statData.traffic.views,
                            clicks: acc.traffic.clicks + statData.traffic.clicks,
                            ctr: calculateCTR(acc.traffic.views + statData.traffic.views, 
                                            acc.traffic.clicks + statData.traffic.clicks)
                        },
                        ads: {
                            views: acc.ads.views + (stat.stats?.ads?.views || 0),
                            clicks: acc.ads.clicks + (stat.stats?.ads?.clicks || 0),
                            ctr: calculateCTR(
                                acc.ads.views + (stat.stats?.ads?.views || 0),
                                acc.ads.clicks + (stat.stats?.ads?.clicks || 0)
                            )
                        }
                    };
                }, formatStats(null));
        }

        function combineStats(historical, current) {
            return {
                traffic: {
                    views: historical.traffic.views + current.traffic.views,
                    clicks: historical.traffic.clicks + current.traffic.clicks,
                    ctr: calculateCTR(
                        historical.traffic.views + current.traffic.views,
                        historical.traffic.clicks + current.traffic.clicks
                    )
                },
                ads: {
                    views: historical.ads.views + current.ads.views,
                    clicks: historical.ads.clicks + current.ads.clicks,
                    ctr: calculateCTR(
                        historical.ads.views + current.ads.views,
                        historical.ads.clicks + current.ads.clicks
                    )
                }
            };
        }

        function calculateCTR(views, clicks) {
            return views > 0 ? (clicks / views) * 100 : 0;
        }

    }, [item._id, item.stats, item.mainStats, historicalStats, selectedDate]);

    const handleStatsClick = (e) => {
        e.stopPropagation();
        navigate(`/admin/news/${item._id}/stats`);
    };

    // Функция для отображения статистики
    const renderStats = () => {
        const currentStats = stats || {
            traffic: { views: 0, clicks: 0, ctr: 0 },
            ads: { views: 0, clicks: 0, ctr: 0 }
        };

        const cellStyle = {
            padding: '12px 24px',
            whiteSpace: 'nowrap',
            fontSize: '14px',
            textAlign: 'center',
            borderRight: '1px solid #e2e8f0',
            color: '#111827'
        };

        return (
            <>
                {/* Основной трафик */}
                <td style={cellStyle}>
                    {currentStats.traffic.views}
                </td>
                <td style={cellStyle}>
                    {currentStats.traffic.clicks}
                </td>
                <td style={cellStyle}>
                    {currentStats.traffic.ctr.toFixed(2)}%
                </td>

                {/* Рекламная статистика */}
                <td style={cellStyle}>
                    {currentStats.ads.views}
                </td>
                <td style={cellStyle}>
                    {currentStats.ads.clicks}
                </td>
                <td style={cellStyle}>
                    {currentStats.ads.ctr.toFixed(2)}%
                </td>
            </>
        );
    };

    // Вспомогательная функция для расчета CTR
    const calculateCTR = (views, clicks) => {
        if (!views || !clicks) return 0;
        return ((clicks / views) * 100).toFixed(2);
    };

    return (
        <tr className={`hover:bg-gray-50 ${!item.isActive ? 'opacity-50' : ''}`}
            style={{ borderBottom: '1px solid #e2e8f0' }}>
            <td style={{ 
                ...newsTableRowStyles.cell,
                borderRight: '1px solid #e2e8f0'
            }}>
                <input
                    type="checkbox"
                    checked={selectedItems.has(item._id)}
                    onChange={() => handleSelectItem(item._id)}
                    style={newsTableRowStyles.checkbox}
                />
            </td>
            <td style={{ 
                ...newsTableRowStyles.cell,
                borderRight: '1px solid #e2e8f0',
                width: '280px',
                minWidth: '280px',
                padding: '12px 8px',
                textAlign: 'left'
            }}>
                <div style={newsTableRowStyles.actionColumn}>
                    <div style={newsTableRowStyles.actionRow}>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={item.isActive}
                                onChange={() => handleToggleActive(item._id, item.isActive)}
                            />
                            <span className="toggle-slider"></span>
                        </div>
                        <div style={{ 
                            padding: '2px 6px',
                            backgroundColor: '#f1f5f9',
                            borderRadius: '4px',
                            fontSize: '12px',
                            color: '#64748b'
                        }}>
                            ID: {item.orderNumber || 'Не задано'}
                        </div>
                    </div>

                    <div style={newsTableRowStyles.infoRow}>
                        <span style={newsTableRowStyles.infoLabel}>Создано:</span>
                        <span>{new Date(item.createdAt).toLocaleDateString('ru-RU')}</span>
                        <span style={newsTableRowStyles.divider}>|</span>
                        <span style={newsTableRowStyles.infoLabel}>Язык:</span>
                        <span>{getLanguageName(item.language)}</span>
                    </div>

                    <div style={newsTableRowStyles.actionRow}>
                        <button 
                            style={newsTableRowStyles.statsLink} 
                            title="Статистика"
                            onClick={handleStatsClick}
                        >
                            <BarChartIcon style={{ fontSize: 16 }} />
                            <span>Статистика</span>
                        </button>
                        <button
                            onClick={() => navigate(`/admin/news/edit/${item._id}`)}
                            style={newsTableRowStyles.actionLink}
                            title="Редактировать"
                        >
                            <EditIcon style={{ fontSize: 16 }} />
                            <span>Редактировать</span>
                        </button>
                        <div style={newsTableRowStyles.menuWrapper} ref={menuRef}>
                            <button
                                onClick={() => setShowMenu(showMenu === item._id ? null : item._id)}
                                style={newsTableRowStyles.menuButton}
                            >
                                •••
                            </button>
                            {showMenu === item._id && (
                                <div 
                                    style={newsTableRowStyles.dropdownMenu} 
                                    onClick={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                >
                                    <button
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onClick={handleOpenUrl}
                                        style={{
                                            ...newsTableRowStyles.menuItem,
                                            color: '#64748b'
                                        }}
                                        type="button"
                                    >
                                        <OpenInNewIcon style={{ fontSize: 16 }} />
                                        <span>Перейти к объявлению</span>
                                    </button>
                                    <div style={newsTableRowStyles.menuDivider} />
                                    <button
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onClick={handleDeleteClick}
                                        style={newsTableRowStyles.menuItem}
                                        type="button"
                                    >
                                        <DeleteIcon style={{ fontSize: 16 }} />
                                        <span>Удалить</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </td>
            <td style={{ 
                ...newsTableRowStyles.cell,
                borderRight: '1px solid #e2e8f0',
                width: '100%',
                textAlign: 'left'
            }}>
                <div style={{ 
                    display: 'flex', 
                    gap: '16px', 
                    alignItems: 'flex-start',
                    width: '100%'
                }}>
                    <div style={newsTableRowStyles.imageContainer}>
                        <img 
                            src={`${BASE_DOMAIN}${item.imageUrl}`}
                            alt={item.title}
                            style={newsTableRowStyles.image}
                            onMouseOver={e => e.target.style.transform = 'scale(1.05)'}
                            onMouseOut={e => e.target.style.transform = 'scale(1)'}
                        />
                    </div>
                    <NewsTitle 
                        title={item.title}
                        sourceUrl={item.sourceUrl}
                    />
                </div>
            </td>
            {renderStats()}
        </tr>
    );
};

export default NewsTableRow;