export const tableStyles = {
    container: {
        overflowX: 'auto',
        marginTop: '16px',
        width: '100%',
        border: '1px solid #e2e8f0',
        borderRadius: '8px',
        backgroundColor: 'white'
    },
    header: {
        textAlign: 'center',
        padding: '12px 16px',
        borderBottom: '2px solid #e5e7eb',
        backgroundColor: '#f8fafc',
        color: '#475569',
        fontSize: '11px',
        fontWeight: '700',
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        borderRight: '1px solid #e5e7eb',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        whiteSpace: 'nowrap'
    },
    row: {
        borderBottom: '1px solid #e5e7eb',
        transition: 'background-color 0.2s ease',
        ':hover': {
            backgroundColor: '#f8fafc'
        }
    },
    cell: {
        padding: '12px 16px',
        fontSize: '14px',
        color: '#475569',
        borderRight: '1px solid #e2e8f0',
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
        textAlign: 'center'
    },
    statsCell: {
        padding: '12px 24px',
        fontSize: '14px',
        color: '#475569',
        borderRight: '1px solid #e2e8f0',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        textAlign: 'center'
    },
    checkbox: {
        cursor: 'pointer',
        width: '16px',
        height: '16px',
        accentColor: '#2a5298',
        outline: 'none',
        borderRadius: '3px'
    },
    groupHeader: {
        backgroundColor: '#f1f5f9',
        fontWeight: '700',
        textTransform: 'uppercase',
        fontSize: '13px',
        color: '#64748b',
        letterSpacing: '0.05em'
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
        whiteSpace: 'nowrap'
    },
    sortIcon: {
        marginLeft: '4px',
        fontSize: '14px',
        color: '#2a5298'
    },
    sortableHeader: {
        cursor: 'pointer',
        userSelect: 'none',
        ':hover': {
            backgroundColor: '#f1f5f9'
        }
    },
    sortArrows: {
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        marginLeft: '2px'
    },
    sortArrow: {
        fontSize: '12px',
        color: '#94a3b8',
        transition: 'color 0.2s ease'
    },
    footer: {
        backgroundColor: '#f8fafc',
        borderTop: '2px solid #e5e7eb'
    },
    footerCell: {
        padding: '8px 16px',
        verticalAlign: 'middle',
        borderRight: '1px solid #e5e7eb',
        fontSize: '14px',
        color: '#1e293b',
        fontWeight: '600',
        backgroundColor: '#f8fafc'
    },
    pagination: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#f8fafc',
        borderTop: '1px solid #e5e7eb',
        marginTop: '1px'
    },
    rowsPerPage: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
    },
    rowsPerPageLabel: {
        fontSize: '14px',
        color: '#64748b'
    },
    rowsPerPageSelect: {
        padding: '4px 24px 4px 8px',
        border: '1px solid #e2e8f0',
        borderRadius: '4px',
        backgroundColor: 'white',
        fontSize: '14px',
        color: '#1e293b',
        cursor: 'pointer',
        outline: 'none',
        appearance: 'none',
        backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 8px center',
        backgroundSize: '12px'
    },
    paginationControls: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    paginationButton: {
        padding: '4px 8px',
        border: '1px solid #e2e8f0',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: '#1e293b',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: '#f1f5f9',
            borderColor: '#cbd5e1'
        },
        ':disabled': {
            cursor: 'not-allowed',
            opacity: 0.5,
            backgroundColor: '#f8fafc'
        }
    },
    paginationInfo: {
        fontSize: '14px',
        color: '#64748b',
        padding: '0 8px',
        minWidth: '120px',
        textAlign: 'center'
    }
};

export const searchStyles = {
    container: {
        position: 'relative',
        width: '400px'
    },
    input: {
        width: '100%',
        padding: '10px 16px',
        paddingLeft: '40px',
        backgroundColor: 'white',
        border: '1px solid #e2e8f0',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#64748b',
        transition: 'all 0.2s ease',
        outline: 'none',
        '::placeholder': {
            color: '#94a3b8'
        },
        ':focus': {
            borderColor: '#2a5298',
            boxShadow: '0 0 0 2px rgba(42, 82, 152, 0.1)'
        }
    },
    icon: {
        position: 'absolute',
        left: '12px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#94a3b8',
        width: '20px',
        height: '20px'
    }
};

export const imageStyles = {
    container: {
        width: '110px',
        height: '110px',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
        position: 'relative'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'transform 0.3s ease'
    }
};

export const buttonStyles = {
    primary: {
        backgroundColor: '#2a5298',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        padding: '10px 20px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        fontSize: '16px',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    action: {
        padding: '6px',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s ease'
    },
    bulkAction: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        padding: '8px 16px',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        color: '#333',
        textAlign: 'left',
        fontSize: '14px'
    },
    filter: {
        padding: '8px 16px',
        border: '1px solid #e2e8f0',
        borderRadius: '8px',
        backgroundColor: 'white',
        color: '#1e293b',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        transition: 'all 0.2s ease',
        ':hover': {
            borderColor: '#94a3b8'
        }
    },
    filterOption: {
        width: '100%',
        padding: '8px 16px',
        border: 'none',
        backgroundColor: 'white',
        color: '#1e293b',
        fontSize: '14px',
        textAlign: 'left',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        ':hover': {
            backgroundColor: '#f1f5f9'
        }
    },
    resetFilter: {
        padding: '8px 16px',
        border: '1px solid #fee2e2',
        borderRadius: '8px',
        backgroundColor: 'white',
        color: '#dc2626',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: '#fee2e2'
        }
    },
    iconButton: {
        width: '36px',
        height: '36px',
        padding: '8px',
        borderRadius: '8px',
        border: '1px solid #e2e8f0',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.2s ease',
        backgroundColor: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.06)',
        position: 'relative',
        overflow: 'hidden'
    },
    statusToggle: {
        padding: '4px 12px',
        borderRadius: '6px',
        border: 'none',
        fontSize: '13px',
        fontWeight: '500',
        cursor: 'pointer',
        transition: 'all 0.2s ease'
    },
    actionButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        padding: '6px 12px',
        borderRadius: '6px',
        border: 'none',
        backgroundColor: 'transparent',
        color: '#475569',
        fontSize: '13px',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: '#f1f5f9'
        }
    },
    actionLink: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        padding: '4px 0',
        border: 'none',
        backgroundColor: 'transparent',
        color: '#475569',
        fontSize: '12px',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        ':hover': {
            color: '#2a5298'
        }
    },
    menuButton: {
        padding: '4px 8px',
        border: 'none',
        backgroundColor: 'transparent',
        color: '#64748b',
        cursor: 'pointer',
        fontSize: '16px',
        lineHeight: '1',
        ':hover': {
            color: '#475569'
        }
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        padding: '8px 16px',
        border: 'none',
        backgroundColor: 'transparent',
        color: '#dc2626',
        cursor: 'pointer',
        fontSize: '12px',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: '#fee2e2'
        }
    }
};

export const bulkActionsStyles = {
    container: {
        backgroundColor: 'white',
        borderRadius: '8px',
        position: 'relative'
    },
    button: (active) => ({
        backgroundColor: active ? '#2a5298' : '#e2e8f0',
        color: active ? 'white' : '#94a3b8',
        border: 'none',
        borderRadius: '6px',
        padding: '10px 20px',
        cursor: active ? 'pointer' : 'not-allowed',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        transition: 'all 0.2s ease',
        fontSize: '14px',
        boxShadow: active ? '0 2px 4px rgba(42, 82, 152, 0.1)' : 'none'
    }),
    counter: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        padding: '2px 8px',
        borderRadius: '12px',
        fontSize: '12px'
    },
    menu: {
        position: 'absolute',
        top: '100%',
        left: 0,
        marginTop: '8px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
        padding: '8px 0',
        zIndex: 1000,
        minWidth: '200px'
    }
};

export const layoutStyles = {
    container: {
        display: 'flex',
        gap: '16px',
        maxWidth: '100%',
        backgroundColor: '#f1f5f9',
        padding: '0',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    headerSection: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
    },
    headerSectionLeft: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        flex: '0 0 auto',
        borderRight: '1px solid #e2e8f0'
    },
    headerSectionCenter: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        flex: '1 1 auto',
        justifyContent: 'center',
        borderRight: '1px solid #e2e8f0'
    },
    headerSectionRight: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        flex: '0 0 auto'
    },
    mainSection: {
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        position: 'relative',
        zIndex: 1,
    },
    header: {
        backgroundColor: '#f8fafc',
        borderRadius: '8px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        padding: '16px',
        width: '100%'
    },
    headerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        gap: '16px'
    },
    headerLeft: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    headerRight: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    toolbarSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
        marginBottom: '16px'
    },
    toolbarLeft: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    toolbarRight: {
        display: 'flex',
        alignItems: 'center'
    },
    headerControls: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    statusFilter: {
        position: 'relative'
    },
    statusMenu: {
        position: 'absolute',
        top: '100%',
        left: 0,
        marginTop: '4px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
        padding: '4px 0',
        zIndex: 1000,
        minWidth: '160px',
        display: 'flex',
        flexDirection: 'column'
    },
    actionColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    actionRow: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        color: '#64748b'
    },
    infoLabel: {
        fontSize: '12px',
        color: '#64748b'
    },
    infoValue: {
        fontSize: '12px',
        color: '#1e293b'
    },
    actionLinks: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    actionText: {
        fontSize: '12px'
    },
    menuWrapper: {
        position: 'relative',
        display: 'inline-block'
    },
    dropdownMenu: {
        position: 'absolute',
        top: '100%',
        right: 0,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
        padding: '4px 0',
        zIndex: 1000,
        minWidth: '160px',
        cursor: 'pointer'
    },
    statsBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px',
        borderBottom: '1px solid #e5e7eb',
        backgroundColor: '#f8fafc',
        borderRadius: '8px 8px 0 0'
    },
    statsGroup: {
        display: 'flex',
        alignItems: 'center',
        gap: '24px'
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    statsLabel: {
        fontSize: '14px',
        color: '#64748b',
        fontWeight: '500'
    },
    statsValue: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#1e293b'
    },
    titleWithStats: {
        display: 'flex',
        alignItems: 'center',
        gap: '24px'
    },
    title: {
        fontSize: '24px',
        fontWeight: '600',
        color: '#1e293b'
    },
    statsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '16px'
    },
    statsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        padding: '8px 16px',
        backgroundColor: '#eef2ff',
        borderRadius: '8px',
        border: '1px solid #e0e7ff',
        whiteSpace: 'nowrap'
    },
    statsTitle: {
        fontSize: '14px',
        color: '#4f46e5',
        fontWeight: '500'
    },
    statsContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        color: '#4f46e5',
        flexShrink: 0
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        fontSize: '14px',
        fontWeight: '500',
        color: '#4f46e5'
    },
    statsActive: {
        color: '#16a34a',
        fontWeight: '600'
    },
    statsInactive: {
        color: '#dc2626',
        fontWeight: '600'
    },
    statsItem: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4px',
        padding: '0 8px'
    },
    statsDivider: {
        color: '#94a3b8',
        padding: '0 8px'
    },
    statsContent: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '4px'
    }
};

export const newsTitleStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: '400px',
        height: '100px',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: '20px',
        color: '#1e293b',
        fontWeight: '600',
        lineHeight: '1.4',
        marginBottom: '4px'
    },
    urlContainer: {
        backgroundColor: '#f8fafc',
        borderRadius: '6px',
        width: '100%',
        border: '1px solid #e2e8f0'
    },
    url: {
        fontSize: '11px',
        color: '#868686',
        textDecoration: 'none',
        display: 'block',
        width: '100%',
        padding: '8px 12px',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: '#f1f5f9',
            borderRadius: '6px'
        }
    }
};

export const newsTableRowStyles = {
    row: {
        borderBottom: '1px solid #e2e8f0',
        backgroundColor: 'white'
    },
    cell: {
        padding: '12px 16px',
        fontSize: '14px',
        color: '#475569',
        borderRight: '1px solid #e2e8f0'
    },
    checkbox: {
        width: '16px',
        height: '16px',
        cursor: 'pointer'
    },
    actionColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '280px',
        minWidth: '280px',
        padding: '4px'
    },
    actionRow: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'flex-start',
        width: '100%'
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '12px',
        color: '#64748b',
        justifyContent: 'flex-start',
        width: '100%'
    },
    infoLabel: {
        color: '#94a3b8'
    },
    divider: {
        color: '#e2e8f0'
    },
    actionLink: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        padding: '4px 8px',
        fontSize: '12px',
        color: '#64748b',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '4px'
    },
    statsLink: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '12px',
        color: '#64748b',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '4px'
    },
    menuWrapper: {
        position: 'relative'
    },
    menuButton: {
        padding: '2px 8px',
        fontSize: '18px',
        lineHeight: '1',
        color: '#64748b',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '4px'
    },
    dropdownMenu: {
        position: 'absolute',
        right: 0,
        top: '100%',
        backgroundColor: 'white',
        border: '1px solid #e2e8f0',
        borderRadius: '4px',
        boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
        zIndex: 10,
        whiteSpace: 'nowrap'
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px 16px',
        width: '100%',
        border: 'none',
        backgroundColor: 'transparent',
        color: '#dc2626',
        cursor: 'pointer',
        fontSize: '14px',
        whiteSpace: 'nowrap'
    },
    imageContainer: {
        width: '100px',
        height: '100px',
        flexShrink: 0,
        borderRadius: '8px',
        overflow: 'hidden',
        border: '1px solid #e2e8f0'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'transform 0.2s ease-in-out'
    },
    menuDivider: {
        height: '1px',
        backgroundColor: '#e2e8f0',
        margin: '4px 0'
    },
    statsCell: {
        padding: '12px 24px',
        fontSize: '14px',
        color: '#475569',
        borderRight: '1px solid #e2e8f0',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        textAlign: 'center'
    }
};

export const globalStyles = `
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .refresh-button {
        position: relative;
        overflow: hidden;
    }

    .refresh-button:hover {
        background-color: #fef2f2 !important;
        border-color: #fecaca !important;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1), 0 2px 4px rgba(0,0,0,0.06) !important;
    }

    .refresh-button:active {
        transform: translateY(1px);
        box-shadow: 0 2px 4px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.06) !important;
    }
`;
