export const labelStyles = {
    base: {
        display: 'block',
        marginBottom: '8px',
        color: '#333',
        fontSize: '14px',
        fontWeight: '500'
    },
    error: {
        color: '#dc2626'
    }
};

export const formGroupStyles = {
    marginBottom: '20px'
};

export const errorMessageStyles = {
    fontSize: '12px',
    color: '#dc2626',
    marginTop: '4px'
}; 